import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import {varTime} from '../../components/Helper';
import ToolHead from './ToolHead';
function ToolSQL() {
    const [data, setData] = useState(null);

    useEffect(() => {
        load_log().finally(() => {
            // const intervalId = setInterval(() => {
            //   load_log();
            // }, 10000); //10s 
        });
    },[]);

    async function load_log(){
      fetch('https://tool.tokencheck.io/logs/')
          .then(response => {
              return response.json();
          })
          .then(data => {
          
              setData(data);
          })    
          .catch(error => {
              console.log('Fetch Error:', error);
      });
    }


    return (
      <>
        <Header/>
        <main>
          <ToolHead/>
            <div className="container mx-auto mt-4 p-4 x-board">
                <div className="items-center justify-center">
                    <table className="table-auto w-full text-md table-border-head">
                        <thead>
                          <tr><td><b>Feature/API</b></td><td><b>node</b></td><td><b>Params</b></td><td><b>Table/Query</b></td><td><b>Last</b></td><td><b>New</b></td></tr>
                        </thead>
                        <tbody>
                            <tr className="hover:bg-slate-900">
                                <td>Raydium New</td><td>raydium.js</td><td>Raydium</td><td>token_pair_track <span className='text-highlight'>(INSERT)</span> 
                                <br/> token_pair <span className='text-highlight'>(INSERT)</span></td><td className='text-orange-500'>{data?varTime(data["raydium"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["raydium-new"]):""}</td>
                            </tr>

                            <tr className="hover:bg-slate-900">
                                <td>Pump New</td><td>pump_new.js</td><td>page</td><td>token_pump <span className='text-highlight'>(INSERT)</span></td>
                                
                                <td className='text-orange-500'>{data?varTime(data["pump_new"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["pump_new-new"]):""}</td>
                            </tr>

                            <tr className="hover:bg-slate-900">
                                <td>Pump King</td><td>pump_king.js</td><td>page</td><td>token_pump_king <span className='text-highlight'>(INSERT)</span></td>
                                
                                <td className='text-orange-500'>{data?varTime(data["pump_king"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["pump_king-new"]):""}</td>
                            </tr>


                            <tr className="hover:bg-slate-900">
                                <td>Scan TX</td><td>raydium_tx</td><td>txLiquid</td><td>token_pair_track <span className='text-highlight'>(SELECT)</span> 
                                <br/> token_pair <span className='color-normal'>(UPDATE)</span></td><td className='text-orange-500'>{data?varTime(data["raydium_tx"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["raydium_tx-new"]):""}</td>
                              </tr>
                            <tr className="hover:bg-slate-900">
                                <td>Scan Meta</td><td>raydium_meta</td><td>mintA</td><td>token_pair_track <span className='text-highlight'>(SELECT)</span> <br/> token_pair <span className='color-normal'>(UPDATE)</span> 
                                            <br/> token_meta <span className='color-normal'>(UPDATE)</span></td><td className='text-orange-500'>{data?varTime(data["raydium_meta"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["raydium_meta-new"]):""}</td>
                            </tr>
                            <tr className="hover:bg-slate-900">
                                <td>Scan Info</td><td>raydium_info</td><td>mintA</td><td>token_pair_track <span className='text-highlight'>(SELECT)</span>
                                    <br/>token_pair <span className='color-normal'>(UPDATE)</span></td>
                                
                                <td className='text-orange-500'>{data?varTime(data["raydium_info"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["raydium_info-new"]):""}</td>
                            </tr>
                            

                            <tr className="hover:bg-slate-900">
                                <td>Scan Dev</td><td>raydium_owner</td><td>blockTime Max[1p]</td>
                                <td>tx_owner <span className='text-highlight'>(SELECT)</span>
                                    <br/>token_pair <span className='color-normal'>(UPDATE)</span></td>
                                
                                <td className='text-orange-500'>{data?varTime(data["raydium_owner"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["raydium_owner-new"]):""}</td>
                            </tr>
                            <tr className="hover:bg-slate-900">
                                <td>Scan Dev</td><td>raydium_owner2</td><td>blockTime Min[48h], Max[10p] </td>
                                <td>tx_owner<span className='text-highlight'>(SELECT)</span>
                                    <br/> token_pair <span className='color-normal'>(UPDATE)</span></td>
                                <td className='text-orange-500'>{data?varTime(data["raydium_owner2"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["raydium_owner2-new"]):""}</td>
                           </tr>

                           <tr className="hover:bg-slate-900">
                                <td>Scan Pump</td><td>raydium_pump.js</td><td>mintA</td><td>token_pair_track <span className='text-highlight'>(SELECT)</span>
                                    <br/>token_pair <span className='color-normal'>(UPDATE)</span></td>
                                    <td className='text-orange-500'>{data?varTime(data["raydium_pump"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["raydium_pump-new"]):""}</td>
                            </tr>

                            <tr className="hover:bg-slate-900">
                                <td>Scan Dex</td><td>raydium_dex.js</td><td>mintA</td><td>token_pair_track <span className='text-highlight'>(SELECT)</span>
                                    <br/>token_pair <span className='color-normal'>(UPDATE)</span></td>
                                    <td className='text-orange-500'>{data?varTime(data["raydium_dex"]):""}</td>
                                <td className='text-highlight'>{data?varTime(data["raydium_dex-new"]):""}</td>
                            </tr>

                            
                            {/* <tr><td colspan={6}></td></tr> */}
                            

                        </tbody>
                    </table>
                </div>
            </div>

            <div className="container mx-auto mt-4 p-4 x-board">
                <div className="items-center justify-center">
                    <table className="table-auto w-full table-border-head">
                        <tbody>

                                    <tr className="hover:bg-slate-900"><td>1</td><td>raydium</td><td>token_pair_track </td><td>txLiquid</td><td>insert token_pair, token_pair_track</td>
                                    </tr>

                                    <tr className="hover:bg-slate-900"><td>1</td><td>pump_new</td><td>token_pump</td><td>mint</td><td>insert/update token_pump</td>
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>1</td><td>pump_king</td><td>token_pump_king</td><td>mint</td><td>insert/update token_pump_king</td>
                                    </tr>


                                    <tr className="hover:bg-slate-900"><td>1</td><td>raydium_tx [1] </td><td>token_pair_track</td><td>blockTime</td><td>update token_pair, token_pair_track[txLiquid]<br/>insert tx_owner[mint]</td>
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>1</td><td>raydium_meta [1] </td><td>token_pair_track </td><td>blockTime</td><td>update token_pair, token_pair_track[mintA]<br/>update/insert token_meta[mint]</td>
                                    </tr>

                                    <tr className="hover:bg-slate-900"><td>1</td><td>raydium_info [1] </td><td>token_pair_track </td><td>blockTime</td><td>update token_pair, token_pair_track[mintA]</td>
                                    </tr>

                                    <tr className="hover:bg-slate-900"><td>1</td><td>raydium_owner [1] </td><td>tx_owner </td><td>blockTime [max]</td><td>update tx_owner[mintLP] <br/> update token_pair[mintLP]</td>
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>1</td><td>raydium_owner2 [1] </td><td>tx_owner </td><td>blockTime [min, max]</td><td>update tx_owner[mintLP] <br/> update token_pair[mintLP]</td>
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>1</td><td>raydium_moonshot [1] </td><td>token_pair_track </td><td>blockTime</td><td>update token_pair, token_pair_track[mintA]</td>
                                    </tr>

                                    <tr className="hover:bg-slate-900"><td>1</td><td>raydium_pump [1] </td><td>token_pair_track </td><td>blockTime</td><td>update token_pair, token_pair_track[mintA]</td>
                                    </tr>

                                    
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="container mx-auto mt-4 p-4 x-board">
                <div className="items-center justify-center">
                    <table className="table-auto w-full table-border-head">
                        <tbody>

                            <tr className="hover:bg-slate-900">
                                <td>api.tokencheck</td><td>api_tokencheck.js</td><td>3000</td>
                                    <td>token_pair <span className='text-highlight'>(SELECT LIST)</span>
                                    <br/>token (pump) <span className='text-highlight'>(SELECT LIST)</span>
                                    <br/>token_pair <span className='text-highlight'>(SELECT 1)</span></td>
                                <td className='text-orange-500'>
                                    <div>{data?varTime(data["token_list"]):""}</div>
                                    <div>{data?varTime(data["token_pump"]):""}</div>
                                    <div>{data?varTime(data["token_item"]):""}</div>
                                </td>
                                <td></td>
                            </tr>
                            <tr className="hover:bg-slate-900">
                                <td>api2.tokencheck</td><td>api_tokencheck2.js</td><td>3002</td>
                                    <td>token info <span className='text-highlight'>(SELECT, UPDATE)</span>
                                    <br/>token meta <span className='text-highlight'>(SELECT, UPDATE)</span>
                                    <br/> tx_owner <span className='text-highlight'>(SELECT, UPDATE)</span></td>
                                <td className='text-orange-500'>
                                    <div>{data?varTime(data["request-info"]):""}</div>
                                    <div>{data?varTime(data["request-meta"]):""}</div>
                                    <div>{data?varTime(data["request-owner"]):""}</div>
                                </td>
                                <td></td>
                            </tr>
                            <tr className="hover:bg-slate-900">
                                <td>api3.tokencheck</td><td>token_liquidity.js</td><td>3008</td>
                                    <td>token_liquidity <span className='text-highlight'>(SELECT)</span>
                                    <br/>request liquid <span className='color-normal'>(UPDATE)</span></td>
                                <td className='text-orange-500'>
                                        {data?varTime(data["token_liquidity"]):""}
                                        <div>{data?varTime(data["request-liquidity"]):""}</div>
                                </td>
                                <td></td>
                            </tr>

                            <tr className="hover:bg-slate-900">
                                <td>tool.tokencheck</td><td>api_tool.js</td><td>3001</td>
                                    <td>all table <span className='text-highlight'>(SELECT)</span></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </main>
      </>
    )
  }
  
  export default ToolSQL
