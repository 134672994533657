import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

export default function ToolNavigation() {
    const [activeItem, setActiveItem] = useState('tool'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        if (location.pathname.includes('/tool-check/tx-owner')) {
          setActiveItem('tx-owner');
        } 
        else if (location.pathname.includes('/tool-check/req-block')){
          setActiveItem('req-block');
        }
        else if (location.pathname.includes('/tool-check/req-tx')){
          setActiveItem('req-tx');
        }
        else if (location.pathname.includes('/tool-check/token')){
          setActiveItem('token');
        }
        else if (location.pathname.includes('/tool-check/req-token')){
          setActiveItem('req-token');
        }
        else if (location.pathname.includes('/tool-check/ray-token')){
          setActiveItem('ray-token');
        }
      
      }, [location]); // update when location changes

    return (
        //navigation
        <>
            <div className="menu-option mr-4">
                <Link to="/tool-check/token" className={activeItem === 'token' ? 'text-highlight underline' : ''}>DB Token</Link>
            </div>
            <div className="menu-option mr-4">
                <Link to="/tool-check/tx-owner" className={activeItem === 'tx-owner' ? 'text-highlight underline' : ''}>DB Owner</Link>
            </div>

            <div className="menu-option mr-4">
                <Link to="/tool-check/req-token" className={activeItem === 'req-token' ? 'text-highlight underline' : ''}>Req Token</Link>
            </div>

            <div className="menu-option mr-4">
                <Link to="/tool-check/req-block" className={activeItem === 'req-block' ? 'text-highlight underline' : ''}>Req Block</Link>
            </div>
            

            <div className="menu-option mr-4">
                <Link to="/tool-check/req-tx" className={activeItem === 'req-tx' ? 'text-highlight underline' : ''}>Req Tx</Link>
            </div>
          
            <div className="menu-option mr-4">
                <Link to="/tool-check/ray-token" className={activeItem === 'ray-token' ? 'text-highlight underline' : ''}>TokenCheck</Link>
            </div>

        </>
    );

}
