import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import ToolHead from '../tool-api/ToolHead';
import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import {extensionLink, varTime} from '../../components/Helper';

/* eslint-disable react-hooks/exhaustive-deps */
function TxBlock() {
    const {mint } = useParams();

    const [mintAddress, setMintAddress] = useState('');
    const [data, setData] = useState(null);
    const [time, setTime] = useState(0);

    const [link, setLink] = useState(null);
    const [dataTx, setDataTx] = useState(null);

    useEffect(() => {
        if(mint != null) setMintAddress(mint);
    },[mint]);

    useEffect(() => {
        if(mintAddress != null) {
            handleLoadDB();
        }
    },[mintAddress]);

    useEffect(() => {
        if(link != null) {
            handleLoadBlock();
        }
    },[link]);


    const handleMintChange = (e) => {
        const _mintAddress = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', _mintAddress);
        setMintAddress(_mintAddress);
    }

    const handleLoadDB = () => {
        setData(null);
        setLink(null);

        if(mintAddress !== ""){
            fetch('https://api.tokencheck.io/token/mint/' + mintAddress)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    // const success = response.success;
                    const data = response.data;

                    if(data != null) {
                        setData(data);
                        const _link = "https://api2.tokencheck.io/tx/block/" + data?.slot + "/" + mintAddress;
                        setLink(_link);
                    }
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });
        }
    }

    const handleLoadBlock = () => {
        if(link !== ""){
            setDataTx(null);
            setTime(0);
            
            fetch(link)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    // const success = response.success;
                    const data = response.data;
                    if(data) setDataTx(data);
                    setTime(response.time);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });
        }
    }

    return (
      <><Header/>
        <div className="p-4 mx-auto">
            <ToolHead/>
            <div className="w-full mt-4 md:flex">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                <div className='w-full x-board p-4'>
                    <div className='items-center text-center'>
                        <div>Mint Token</div>
                        <div><input className="w-full p-1.5 text-black"    
                                                defaultValue={mintAddress}     
                                                // value = {current_tx}
                                                onChange={handleMintChange}  
                                                type="text"/>
                        </div>
                    </div>
                    <div className="flex mt-10 gap-4">
                        <div className='w-1/2'>
                            <div className='text-orange-500'>Check DB : {data?varTime(data.blockTime):""}</div>
                            <div className='text-xs truncate'>{extensionLink(`https://tool.tokencheck.io/tx-owner/mint/${mintAddress}`)}</div>
                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                <pre>
                                    {
                                        data?JSON.stringify(data,undefined, 2):""
                                    }
                                </pre>
                            </div>

                        </div>

                        <div className='w-1/2'>
                            <div>Slot</div>
                            <div><input className="w-full p-1.5 text-black"    
                                                    defaultValue={data?.slot}     
                                                    // value = {current_tx}
                                                    // onChange={handleMintChange}  
                                                    type="text"/>
                            </div>
                            <div className='text-xs truncate'>{extensionLink(link)}</div>
                            <div>
                                <button className='button border p-1 pl-2 pr-2 mt-4' onClick={handleLoadBlock}>Reload TX</button>
                            </div>

                            <div className='mt-4'>TX : <span className='text-yellow-300'>{time.toFixed(0)} ms</span></div>
                            
                            <div className='whitespace-normal bg-black overflow-x-auto'>
                                {
                                    dataTx?.map((item, index) => {
                                        const solscan_link = "https://solscan.io/tx/" + item;
                                        return(<div>                                   
                                                    <div className='text-highlight'><a rel="noreferrer" target="_blank" href={solscan_link}>{item}</a></div>                                                   
                                                </div>);
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
                </div>
            </div>
        </div>
      </>
    )
}
  

export default TxBlock;