import React, { useState, useEffect } from 'react';
import {shortAddress2, dollarCap, extensionLink, extensionLink2, externalMarket2, varTime , varTime2, solNumber} from '../../components/Helper';
import PumpModal from './PumpModal';

const AnalysisPumpNewArea = () => {
    const [page, setPage] = useState(1);
    const nextPage = () => {setPage(page + 1)};
    const prevPage = () => {if(page > 1) setPage(page - 1)};
    const [data, setData] = useState([]);

    //for model - maybe remove for no need to do
    const [modalContent, setModalContent] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = (item) => {setIsModalOpen(true); setModalContent(item) ;}
    const closeModal = () => {setIsModalOpen(false); }


    useEffect(() => {
        loadData();
    }, [page]);

    async function loadData(){
        const offset = 20 * (page-1);
        var params = {"offset" : offset , "limit" : 20, "sort" : "created_timestamp", "order" : "DESC"}; //blockTime

        fetch('https://tool.tokencheck.io/analysis/pump-new',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
          )
          .then(response => {
            return response.json(); 
          })
          .then(data => {     
            setData(data);
          })
          .catch(error => {
            console.log('Fetch Error:', error);
          });
    }


    function displayComplete(value){
        if(value == 0) return (<div className="text-gray-500"><i className="fa-solid fa-circle text-xs mr-2"></i> No</div>);
        else return (<div className="text-highlight"><i className="fa-solid fa-circle text-xs color-good mr-2"></i> Done</div>);
    }

    function showDexscreener(pool){
        if(pool == null) return '-';

        return extensionLink2("https://dexscreener.com/solana/" + pool, shortAddress2(pool));
    }


    return (
        <div className="mx-auto md:mt-4  text-sm">

        {/* <td className="text-highlight"><button onClick={() => openModal(item)}>[Pump]</button></td>
        <PumpModal isOpen={isModalOpen} onClose={closeModal} content ={modalContent}/> */}

            <div className="overflow-x-auto	pb-10 x-board md:mt-4 p-4">
                <table className="table-auto w-full table-th-left" key="list">
                    <thead style={{"border-bottom": "1px #eee solid"}}> 
                        <tr><th>No</th><th>IC</th><th>Address</th><th>Pump</th><th>Name</th><th>Symbol</th><th>Tele</th><th>Twitter</th>
                            <th>Web</th><th>C</th><th>Complete</th><th>DEX</th><th>SOL</th><th>$Cap</th><th>Reply</th><th>Check</th><th>S</th><th>API</th></tr>
                    </thead>

                    {data.map((item, index) => {

                            return (

                                <tbody>
                                    <tr key={index}>
                                        <td className='text-gray-500' >{index + (page - 1)  * 20 + 1}</td>
                                        <td className='px-2 py-1'><img src={item.image_uri} className="token-image-ic" alt={item.name} /></td>
                                        <td className="text-highlight">{extensionLink2("/token/" + item.mint, shortAddress2(item.mint))}</td>
                                        <td className="text-gray-500">{extensionLink2("https://pump.fun/" + item.mint, "[Pump]")}</td>

                                        <td>{item.name}</td>
                                        <td>{item.symbol}</td>

                                        <td className='px-2 py-1 max-w-48 break-all'>{extensionLink(item.telegram)}</td>
                                        <td>{extensionLink2(item.twitter, "Twitter")}</td>                             
                                        <td>{extensionLink2(item.website, "Web")}</td>
                                        <td className="px-2 py-1 text-yellow-300">{varTime2(item.created_timestamp / 1000)}</td>

                                        <td>{displayComplete(item.complete)}</td>
                                        <td className="text-gray-500">{showDexscreener(item.raydium_pool)}</td>
                                        <td>{solNumber(item.virtual_sol_reserves )}</td>
                                        <td>{dollarCap(item.usd_market_cap)}</td>
                                        <td>{item.reply_count}</td>

                                         <td className="text-gray-500">{varTime2(item.updated_time)}</td>
                                         <td className="text-gray-500">{item.scan}</td>
                                         <td className="text-gray-500">{extensionLink2("https://frontend-api.pump.fun/coins/" + item.mint, "[API]")}</td>
                                    </tr>
                                </tbody>

                            )
                    })}

                </table>
            </div>


            <div className="w-full mx-auto  text-center fixed bottom-0 left-0 right-0 bg-head text-highlight p-1"> 
                <div className="justify-self-end">
                    <div className="flex justify-center space-x-2">
                        <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={prevPage}>[ &lt;&lt; ]</button>
                        <span>{page}</span>
                        <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={nextPage}>[ &gt;&gt; ]</button>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default AnalysisPumpNewArea