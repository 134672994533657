import React from 'react';
import Header from '../../components/header/Header';
import ToolHead from './ToolHead';
import {extensionLink} from '../../components/Helper';
function ToolApi() {
    return (
      <>
        <Header/>
        <main>
          <ToolHead/>
          <div className="container mx-auto mt-4 p-4 x-board">
                <div className="items-center justify-center flex">
                    <table className="table-auto w-full text-md">
                         <tbody>    
                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>Third API</b></td></tr>
                                <tr><td>1</td><td>Helius stats</td><td>month</td><td>{extensionLink("https://dev.helius.xyz/usage/my")}</td></tr>
                                <tr><td>1</td><td>Helius RPC</td><td>month</td><td>{extensionLink("https://mainnet.helius-rpc.com/?api-key=ed893bb8-1216-4383-a713-c072fa745c81")}</td></tr>




                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>Analysis / Group</b></td></tr>
                                <tr><td>1</td><td>Creator Type</td><td>creator</td><td>{extensionLink("https://tool.tokencheck.io/token/group/creator")}</td></tr>
                                <tr><td>2</td><td>Error Type</td><td>error</td><td>{extensionLink("https://tool.tokencheck.io/token/group/error")}</td></tr>
                                <tr><td>3</td><td>MintB</td><td>error</td><td>{extensionLink("https://tool.tokencheck.io/token/group/mintB")}</td></tr>


                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>Analysis / Number</b></td></tr>
                                <tr><td>2</td><td>Empty</td><td>empty</td><td>{extensionLink("https://tool.tokencheck.io/token/number/empty")}</td></tr>
                                <tr><td>3</td><td>Empty Info</td><td>empty-info</td><td>{extensionLink("https://tool.tokencheck.io/token/number/empty-info")}</td></tr>
                                <tr><td>4</td><td>Empty Meta</td><td>empty-meta</td><td>{extensionLink("https://tool.tokencheck.io/token/number/empty-meta")}</td></tr>
                                <tr><td>5</td><td>Pump-fun</td><td>pump-amount</td><td>{extensionLink("https://tool.tokencheck.io/token/number/pump-amount")}</td></tr>
                                <tr><td>6</td><td>Pump-flag</td><td>pump-flag</td><td>{extensionLink("https://tool.tokencheck.io/token/number/pump-flag")}</td></tr>
                                <tr><td>7</td><td>Pump-fake</td><td>pump-fake</td><td>{extensionLink("https://tool.tokencheck.io/token/number/pump-fake")}</td></tr>
                                <tr><td>8</td><td>Pump.fun</td><td>day</td><td>{extensionLink("https://tool.tokencheck.io/analysis/pump/{day}")}</td></tr>
                                <tr><td>9</td><td>Pinksale</td><td>day</td><td>{extensionLink("https://tool.tokencheck.io/analysis/pink/{day}")}</td></tr>

                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>pump.fun</b></td></tr>
                                <tr><td>1</td><td>Pump</td><td>total</td><td>{extensionLink("https://tool.tokencheck.io/pump/number")}</td></tr>


                                
                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>Twitter</b></td></tr>
                                <tr><td>1</td><td>User</td><td>[username]</td><td>{extensionLink("https://tool.tokencheck.io/twitter/user/")}</td><td>user/details</td></tr>
                                <tr><td>2</td><td>Following</td><td>[user_id]</td><td>{extensionLink("https://tool.tokencheck.io/twitter/following/")}</td><td>user/following</td></tr>
                                <tr><td>3</td><td>Subscribe</td><td>[channel_id, user_id]</td><td>{extensionLink("https://tool.tokencheck.io/twitter/subscribe/")}</td><td>user/following</td></tr>
                                <tr><td>4</td><td>Tweet</td><td>[user_id, content]</td><td>{extensionLink("https://tool.tokencheck.io/twitter/tweet/")}</td><td>user/tweets</td></tr>
                                
                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>Token</b></td></tr>
                                <tr><td>1</td><td>Meta | Web3</td><td>[mint]</td><td>{extensionLink("https://tool.tokencheck.io/token/meta-web3/{mint}")}</td><td>getPDA, load</td></tr>
                                <tr><td>2</td><td>Meta | Umi</td><td>[mint]</td><td>{extensionLink("https://tool.tokencheck.io/token/meta-umi/{mint}")}</td><td>fetchMetadataFromSeeds</td></tr>
                                <tr><td>3</td><td>Tx-Accounts</td><td>[Tx]</td><td>{extensionLink("https://tool.tokencheck.io/tx/account/{tx}")}</td><td>getParsedTransaction</td></tr>
                                <tr><td>4</td><td>Tx-Instruction</td><td>[Tx]</td><td>{extensionLink("https://tool.tokencheck.io/tx/instruction/{tx}")}</td><td>getParsedTransaction</td></tr>
                                <tr><td>5</td><td>Tx-Logs</td><td>[Tx]</td><td>{extensionLink("https://tool.tokencheck.io/tx/log/{tx}")}</td><td>getParsedTransaction</td></tr>


                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>Token - DB</b></td></tr>
                                <tr><td>1</td><td>Token Info</td><td>[mintA]</td><td>{extensionLink("https://api.tokencheck.io/token/mint/")}</td></tr>
                                <tr><td>2</td><td>Token Info</td><td>[txLiquid]</td><td>{extensionLink("https://api.tokencheck.io/token/tx/")}</td></tr>
                                <tr><td>3</td><td>Other Market</td><td>mintA</td><td>{extensionLink(" https://api.tokencheck.io/market/request/{mintA}")}</td></tr>
                                <tr><td>4</td><td>New token/hour</td><td>hour</td><td>{extensionLink("https://api.tokencheck.io/analysis/hour/{hour}")}</td></tr>
                                <tr><td>5</td><td>New token/day</td><td>day</td><td>{extensionLink("https://api.tokencheck.io/analysis/day/{day}")}</td></tr>
                                


                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>Reload</b></td></tr>
                                <tr><td>1</td><td>Token</td><td>[txLiquid]</td><td>{extensionLink("https://api.tokencheck.io/tx/load/")}</td></tr>
                                <tr><td>2</td><td>Info</td><td>[mintA]</td><td>{extensionLink("https://api.tokencheck.io/token/request-info/")}</td><td>getParsedAccountInfo</td></tr>
                                <tr><td>3</td><td>Meta</td><td>[mintA]</td><td>{extensionLink("https://api.tokencheck.io/token/request-metadata/")}</td><td>getAccountInfo</td></tr>
                                <tr><td>4</td><td>Liquidity</td><td>[liquidityB]</td><td>{extensionLink("https://api.tokencheck.io/token/liquidity/")}</td><td>getBalance</td></tr>
                                <tr><td>5</td><td>Tokens/Owner</td><td>[owner, mintA, mintLP]</td><td>{extensionLink("https://api.tokencheck.io/account/token-amount/{owner}/{mint_address},{mintLP}")}</td><td>getTokenAccountsByOwner</td></tr>
                                <tr><td>6</td><td>Txs/Owner</td><td>[owner, mintLP]</td><td>{extensionLink("https://api.tokencheck.io/tx/owner/ {mintLP}/{owner}")}</td><td>getParsedProgramAccounts</td></tr>
                                <tr><td>7</td><td>Txs/Block</td><td>[block, mintA]</td><td>{extensionLink("https://api.tokencheck.io/tx/block/{slot}/{mintA}")}</td><td>getBlock</td></tr>
                                
                                <tr><td><i className="fa-solid fa-square color-normal"></i></td><td><b className='color-normal'>Cron Job</b></td></tr>
                                <tr><td>1</td><td>Raydium Tx</td><td>5s</td><td>node raydium2.js</td><td>getSignaturesForAddress</td></tr>
                                <tr><td>2</td><td>Tx Detail</td><td>5s</td><td>node raydium_tx.js</td><td>getParsedTransaction</td></tr>
                                <tr><td>3</td><td>Token Meta</td><td>2s</td><td>node raydium_meta.js</td><td>getAccountInfo</td></tr>

                          </tbody>             
                    </table>
                </div>
            </div>
        </main>
      </>
    )
  }
  
  export default ToolApi