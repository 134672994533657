import React, { useState, useEffect } from 'react';
import AnalysisPumpNewArea from './AnalysisPumpNewArea';
import Header from '../../components/header/Header';
import AnalysisHead from './AnalysisHead';

function AnalysisPumpNew() {
    return (
      <>
        <Header/>
        <main>
          <AnalysisHead/>
          <AnalysisPumpNewArea/>
        </main>
      </>
    )
  }
  
  export default AnalysisPumpNew