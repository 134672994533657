import React, { useState, useEffect } from 'react';
import {shortAddress2, extensionLink, extensionLink2, externalMarket2, varTime2 } from '../../components/Helper';

const AnalysisRaydiumArea = () => {
    const [page, setPage] = useState(1);
    const nextPage = () => {setPage(page + 1)};
    const prevPage = () => {if(page > 1) setPage(page - 1)};
    const [data, setData] = useState([]);

    useEffect(() => {
        loadData();
    }, [page]);

    async function loadData(){
        const offset = 20 * (page-1);
        var params = {"offset" : offset , "limit" : 20, "sort" : "blockTime", "order" : "DESC"}; //blockTime

        fetch('https://tool.tokencheck.io/analysis/raydium',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
          )
          .then(response => {
            return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
   
            setData(data);
          })
          .catch(error => {
            console.log('Fetch Error:', error);
          });
    }

    function showDexscreener(pool){
        if(pool == null) return '-';

        return extensionLink2("https://dexscreener.com/solana/" + pool, "[Dex]");
    }

    return (
        <div className="mx-auto md:mt-4 text-sm">
            <div className="overflow-x-auto	pb-10 x-board p-4">
                <table className="table-auto w-full table-th-left" key="list">
                    <thead style={{"border-bottom": "1px #eee solid"}}> 
                        <tr><th>No</th><th>IC</th><th>Address</th><th>DEX</th><th>Name</th><th>Symbol</th><th>Block</th><th>Mint Tool</th><th>Tele</th><th>Twitter</th>
                            <th>Web</th><th></th><th></th></tr>
                    </thead>

                    {data.map((item, index) => {
                            var content_uri;
                            var extensions;
                            if(item.content_uri != null){
                                content_uri = JSON.parse(item.content_uri);
                                if(content_uri != null && content_uri.hasOwnProperty("extensions")){
                                    extensions = content_uri.extensions;
                                }
                            }

                            var token_creator;
                            if(item.content_uri != null){
                                token_creator = JSON.parse(item.token_creator);
                            }


                            return (

                                <tbody>
                                    <tr key={index}>
                                        <td className="text-gray-500">{index + (page - 1)  * 50 + 1}</td>
                                        <td className='px-2 py-1'><img src={content_uri?.image} className="token-image-ic" alt={content_uri?.name} /></td>
                                        <td className="text-highlight">{extensionLink2("/token/" + item.mint, shortAddress2(item.mint))}</td>
                                        <td className="text-gray-500">{showDexscreener(item.mint)}</td>
                                        <td>{content_uri?.name}</td>
                                        <td>{content_uri?.symbol}</td>
                                        <td className="px-2 py-1 min-w-20 text-yellow-300">{varTime2(item.blockTime)}</td>
                                        <td className='text-yellow-300'>{externalMarket2(token_creator, item.mint)}</td>
                                        <td className='px-2 py-1 max-w-48 break-all'>{extensionLink(extensions?.telegram)}</td>
                                        <td>{extensionLink2(extensions?.twitter, "Twitter")}</td>
                                        <td>{extensionLink2(extensions?.website, "Web")}</td>
                                       
                                        <td className="text-gray-500">{extensionLink2(item.token_uri, "URI")}</td>
                                        <td className="text-highlight">{extensionLink2("/tool-check/tx-block/" + item.mint, "Block")}</td>

                                    </tr>
                                </tbody>

                            )
                    })}

                </table>
            </div>

            <div className="w-full mx-auto  text-center fixed bottom-0 left-0 right-0 bg-head text-highlight p-1"> 
                <div className="justify-self-end">
                    <div className="flex justify-center space-x-2">
                        <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={prevPage}>[ &lt;&lt; ]</button>
                        <span>{page}</span>
                        <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={nextPage}>[ &gt;&gt; ]</button>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default AnalysisRaydiumArea