import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import ToolHead from '../tool-api/ToolHead';

import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import {extensionLink, varTime} from '../../components/Helper';


function Token() {
    const {mint } = useParams();

    const [token, setToken] = useState('');
    const [data, setData] = useState(null);
    const [dataMeta, setDataMeta] = useState(null);
    const [dataLiquid, setDataLiquid] = useState(null);
    const [dataTxOwner, setDataTxOwner] = useState(null);
    const [dataTrack, setDataTrack] = useState(null);

    useEffect(() => {
        if(mint != null) setToken(mint);
    },[mint]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(token != null) {
            handleLoadDB();
        }
    },[token]);
    /* eslint-disable react-hooks/exhaustive-deps */


    const handleMintChange = (e) => {
        const _token = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', _token);
        setToken(_token);
        handleLoadDB();
    }

    const handleLoadDB = () => {
        setData(null);
        setDataMeta(null);
        setDataLiquid(null);

        if(token !== ""){
            fetch('https://api.tokencheck.io/token/mint/' + token)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setData(data);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });

            fetch('https://api.tokencheck.io/token/mint-meta/' + token)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setDataMeta(data);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });

            fetch('https://api.tokencheck.io/token/mint-liquidity/' + token)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setDataLiquid(data);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });

            fetch('https://api.tokencheck.io/token/mint-tx-owner/' + token)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setDataTxOwner(data);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });

            fetch('https://api.tokencheck.io/token/mint-track/' + token)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setDataTrack(data);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });
        }
    }


    
    return (
      <><Header/>
        <div className="mx-auto p-4">
            <ToolHead/>
            <div className="w-full mt-4 md:flex">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                    <div className=''>
                            <div className='w-full mx-auto x-board p-4 '>
                                <div className='items-center text-center'>
                                    <div>Mint Token</div>
                                    <div><input className="w-full p-1.5 text-black"    
                                                            defaultValue={token}     
                                                            // value = {current_tx}
                                                            onChange={handleMintChange}  
                                                            type="text"/></div>
                                </div>

                                <div className="flex mt-10 gap-4">
                                    <div className='w-1/2'>
                                        <div className='text-orange-500'>Token Pair : {data?varTime(data.blockTime):""}</div>
                                        <div className='text-xs truncate'>{extensionLink(`https://api.tokencheck.io/token/mint/${token}`)}</div>
                                        <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                            <pre>
                                                {
                                                    data?JSON.stringify(data,undefined, 2):""
                                                }
                                            </pre>
                                        </div>
                                    </div>
                                    <div className='w-1/2'>
                                            <div className='text-orange-500'>Token Meta : {dataMeta?varTime(dataMeta.created_time):""}</div>
                                            <div className='text-xs truncate'>{extensionLink(`https://api.tokencheck.io/token/mint-meta/${token}`)}</div>

                                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                            <pre>
                                                {
                                                    dataMeta?JSON.stringify(dataMeta,undefined, 2):""
                                                }
                                            </pre>
                                            </div>

                                            <div className='text-orange-500'>Token Liquidity</div>
                                            <div className='text-xs truncate'>{extensionLink(`https://api.tokencheck.io/token/mint-liquidity/${token}`)}</div>

                                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                            <pre>
                                                {
                                                    dataLiquid?JSON.stringify(dataLiquid,undefined, 2):""
                                                }
                                            </pre>
                                            </div>

                                            <div className='text-orange-500'>Token Owner</div>
                                            <div className='text-xs truncate'>{extensionLink(`https://api.tokencheck.io/token/mint-tx-owner/${token}`)}</div>

                                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                            <pre>
                                                {
                                                    dataTxOwner?JSON.stringify(dataTxOwner,undefined, 2):""
                                                }
                                            </pre>
                                            </div>


                                            <div className='text-orange-500'>Token Track</div>
                                            <div className='text-xs truncate'>{extensionLink(`https://api.tokencheck.io/token/mint-track/${token}`)}</div>

                                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                            <pre>
                                                {
                                                    dataTrack?JSON.stringify(dataTrack,undefined, 2):""
                                                }
                                            </pre>
                                            </div>

                                        
                                    </div>
                                    

                                </div>
                            </div>

                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
  

export default Token;