import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import ToolHead from '../tool-api/ToolHead';

import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import {extensionLink} from '../../components/Helper';

function RequestToken() {

    const {mint } = useParams();

    const [token, setToken] = useState('');
    const [dataInfo, setDataInfo] = useState(null);
    const [dataMeta, setDataMeta] = useState(null);
    const [data, setData] = useState(null);
    const [tx, setTx] = useState('');

    useEffect(() => {
        if(mint != null) setToken(mint);
    },[mint]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(token != null) {
            handleLoadDB();
        }
    },[token]);
    /* eslint-disable react-hooks/exhaustive-deps */


    const handleMintChange = (e) => {
        const _token = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', _token);
        setToken(_token);
        handleLoadDB();
    }

    const handleLoadDB = () => {
        setData(null);
        setDataInfo(null);
        setDataMeta(null);

        if(token !== ""){
            fetch('https://api.tokencheck.io/token/mint/' + token)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setData(data);
                    if(data != null) setTx(data.txLiquid);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });

            fetch('https://tool.tokencheck.io/token/request-info/' + token)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setDataInfo(data);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });

            fetch('https://tool.tokencheck.io/token/request-meta/' + token)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setDataMeta(data);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });

            
        }
    };

    return (
      <><Header/>
        <div className="p-4 mx-auto">
            <ToolHead/>
            <div className="w-full mt-4 md:flex">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                    <div className='w-full mx-auto x-board p-4 '>
                        <div className='items-center text-center'>
                            <div>Token</div>
                            <div><input className="w-full p-1.5 text-black"    
                                                    defaultValue={token}     
                                                    // value = {current_tx}
                                                    onChange={handleMintChange}  
                                                    type="text"/></div>
                        </div>

                        <div className="flex mt-10 gap-4">
                            <div className='w-1/2'>
                                <div className='text-orange-500'>Token Pair</div>
                                <div className='text-xs truncate'>R : {extensionLink(`https://api2.tokencheck.io/tx/load/${tx}`)}</div>
                                <div className='text-xs truncate'>DB : {extensionLink(`https://api.tokencheck.io/token/mint/${token}`)}</div>
                                <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                    <pre>
                                        {
                                            data?JSON.stringify(data,undefined, 2):""
                                        }
                                    </pre>
                                </div>
                            </div>
                            <div className='w-1/2'>

                                <div className='text-orange-500'>Request Info</div>
                                <div className='text-xs truncate'>R : {extensionLink(`https://tool.tokencheck.io/token/request-info/${token}`)}</div>
                                <div className='text-xs truncate'>DB : {extensionLink(`https://api2.tokencheck.io/token/request-info/${token}`)}</div>
                                <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                    <pre>
                                        {
                                            dataInfo?JSON.stringify(dataInfo,undefined, 2):""
                                        }
                                    </pre>
                                </div>

                                    <div className='text-orange-500'>Request Meta</div>
                                    <div className='text-xs truncate'>R : {extensionLink(`https://tool.tokencheck.io/token/request-meta/${token}`)}</div>
                                    <div className='text-xs truncate'>DB : {extensionLink(`https://api2.tokencheck.io/token/request-meta/${token}`)}</div>

                                    <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                    <pre>
                                        {
                                            dataMeta?JSON.stringify(dataMeta,undefined, 2):""
                                        }
                                    </pre>
                                    </div>
                                
                            </div>
                            

                        </div>
                    </div>

                </div>
            </div>
        </div>
      </>
    )
}
  

export default RequestToken;