import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import ToolHead from '../tool-api/ToolHead';

function ToolCheck() {
    return (
      <><Header/>
        <div className="p-4 mx-auto">
            <ToolHead/>
            <div className="w-full mt-4 md:flex">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                    TOOL CHECK!!
                </div>
            </div>
        </div>
      </>
    )
  }
  

export default ToolCheck;