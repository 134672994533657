import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

export default function ToolHead() {
    const [activeItem, setActiveItem] = useState('tool-api'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        if (location.pathname.includes('/tool-check')){
          setActiveItem('tool-check');
        }
        else if (location.pathname.includes('/tool-api')){
            setActiveItem('tool-api');
        } 
        else if (location.pathname.includes('/tool-sql')){
            setActiveItem('tool-sql');
        } 

      }, [location]); // update when location changes

    return (
        //navigation
        <div className='flex justify-center mt-4 gap-4'>
            <div>
                <Link to="/tool-api/" className={activeItem === 'tool-api' ? 'text-highlight underline underline-offset-4' : ''}>API</Link>
            </div>
            <div>
                <Link to="/tool-sql/" className={activeItem === 'tool-sql' ? 'text-highlight underline underline-offset-4' : ''}>SQL</Link>
            </div>

            <div>
                <Link to="/tool-check/" className={activeItem === 'tool-check' ? 'text-highlight underline underline-offset-4' : ''}>CHECK</Link>
            </div>

        </div>
    );

}
