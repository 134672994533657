import React, { useState, useEffect } from 'react';
import AnalysisGameArea from './AnalysisGameArea';
import Header from '../../components/header/Header';
import AnalysisHead from './AnalysisHead';

function AnalysisGame() {
    return (
      <>
        <Header/>
        <main>
          <AnalysisHead/>
          <AnalysisGameArea/>
        </main>
      </>
    )
  }
  
  export default AnalysisGame