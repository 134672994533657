import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import ToolHead from '../tool-api/ToolHead';
import React, { useState, useEffect } from 'react';
import {extensionLink, extensionLink2, shortAddress} from '../../components/Helper';
import { useParams} from 'react-router-dom';

/* eslint-disable react-hooks/exhaustive-deps */
const RayToken = () => {
    //mint-token
    const {mint } = useParams();
    const [token, setToken] = useState(null);
    const handleMintChange = (e) => {
        const _token = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', _token);
        setToken(_token);
    }

    //data
    const [tokenData, setTokenData] = useState(null);
    const [metaData, setMetaData] = useState(null);
    const [metaRequest, setMetaRequest] = useState(null);
    const [holdersRequest, setHoldersRequest] = useState(null);
    const [txOwnerData, setTxOwnerData] = useState(null);
    const [liquidityToken, setLiquidityToken] = useState(null);
    const [TokenDev, setTokenDev] = useState(null);
    const [TxOwner, setTxOwner] = useState(null);


    useEffect(() => {
        if(mint != null) setToken(mint);
    },[mint]);

    
    useEffect(() => {
        if(token !== null){
            loadDataToken();
        } 
    }, [token]);


    useEffect(() => {
        if(tokenData){
            //load meta
            loadDataMeta();
            requestDataMeta();
            requestDataHolders();

            loadTxOwner(tokenData.data?.owner);
            token_liquidity(tokenData.data?.liquidityA, tokenData.data?.liquidityB);
            requestLPTranx(tokenData.data?.mintLP, tokenData.data?.owner);
            requestTokenByDev(tokenData.data?.owner, tokenData.data?.mintLP);
        }
    },[tokenData]);


    const [totalTime, setTotalTime] = useState(0);
    
    useEffect(() => {
         let time = 0;
         if(tokenData) time += tokenData.time; //1
         if(metaData) time += metaData.time; //2 
         if(metaRequest) time += metaRequest.time; //3
         if(holdersRequest) time += holdersRequest.time; //4
         if(txOwnerData) time += txOwnerData.time; //5
         if(liquidityToken) time += liquidityToken.time; //6

         if(TxOwner) time += TxOwner.time; //5
         if(TokenDev) time += TokenDev.time; //6

         setTotalTime(time);

    },[tokenData,metaData, metaRequest,holdersRequest, txOwnerData, liquidityToken, TxOwner,  TokenDev ]);
    

    function loadDataToken(){
        fetch('https://api.tokencheck.io/token/mint/' + token)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                // const data = response.data;
                // const token_time = response.time;
                setTokenData(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }

    function loadDataMeta(){
        fetch('https://api.tokencheck.io/token/mint-meta/' + token)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
              
                setMetaData(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }


    function loadTxOwner(owner){
        fetch('https://api.tokencheck.io/token/tx-owner/' + token + "/" + owner)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
              
                setTxOwnerData(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }


    function requestDataMeta(){
        fetch('https://api2.tokencheck.io/token/request-meta/' + token)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
              
                setMetaRequest(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }

    function requestDataHolders(){
        fetch('https://api2.tokencheck.io/holders/' + token)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
              
                setHoldersRequest(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }


    function token_liquidity(liquidityA, liquidityB){
            if(liquidityA == null || liquidityB == null) return;
            const params = {liquidityB : liquidityB , liquidityA : liquidityA, mint : token, index : 0, page: 0};

            fetch('https://api3.tokencheck.io/liquidity/',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(params)
                    }
                )
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                  
                    setLiquidityToken(data);
  
                })
                .catch(error => {
                  console.log('Fetch Error:', error);
                });
      }


      function requestLPTranx(_mintLP, _owner){

        fetch('https://api2.tokencheck.io/tx/owner/' + _mintLP + "/" + _owner)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setTxOwner(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }


    function requestTokenByDev(owner, mintLP){
        if(owner == null) return;
        
        let url;
        if(mintLP != null && mintLP !== ''){
            url = 'https://api2.tokencheck.io/account/token-amount/' + owner + "/" + token + "," + mintLP;
        }
        else  url = 'https://api2.tokencheck.io/account/token-amount/' + owner + "/" + token;

        fetch(url)
          .then(response => {
              return response.json(); 
          })
          .then(data => {
                setTokenDev(data); 
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }



    return (
      <><Header/>
        <div className="mx-auto p-4">
            <ToolHead/>
            <div className="w-full mt-4 md:flex">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>

                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                    <div className='w-full mx-auto x-board p-4 '>
                        <div className='items-center text-center'>
                            <div>Mint Token</div>
                            <div><input className="w-full p-1.5 text-black"    
                                        defaultValue={token}     
                                        onChange={handleMintChange}  
                                        type="text"/>
                            </div>
                        </div>
                        <div className='items-center mt-8'>
                        
                            <table className='table-auto w-full text-md table-border-head'>
                                <thead className='text-left'>
                                    <tr><th>No</th><th>Type</th><th className='px-2'>Table</th><th className='px-2'>Method</th><th>Index</th><th>Full Link</th><th>P:ms</th></tr>
                                </thead>

                                <tbody>

                                    <tr className="hover:bg-slate-900"><td>1</td><td>Query</td><td className='text-orange-500 px-2'>token_pair</td><td className='px-2'>GET</td><td>mintA</td>
                                        <td className="text-link">{extensionLink2(`https://api.tokencheck.io/token/mint/${token}`, 'https://api.tokencheck.io/token/mint/' + shortAddress(token))}</td>
                                        <td className='text-yellow-300'>{tokenData?.time.toFixed(1)}</td>
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>2</td><td>Query </td><td className='text-orange-500 px-2'> token_meta</td><td className='px-2'>GET</td><td>mint</td>
                                        <td className="text-link">{extensionLink2(`https://api.tokencheck.io/token/mint-meta/${token}`, 'https://api.tokencheck.io/token/mint-meta/' + shortAddress(token))}</td>
                                        <td className='text-yellow-300'>{metaData?.time.toFixed(1)}</td>
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>3</td><td>Query </td><td className='text-orange-500 px-2'> tx_owner</td><td className='px-2'>GET</td><td>mint, owner</td>
                                        <td className="text-link">{extensionLink(`https://api.tokencheck.io/token/tx-owner/`)}</td>
                                        <td className='text-yellow-300'>{txOwnerData?.time.toFixed(1)}</td>
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>4</td><td>Request </td><td className='text-orange-500 px-2'> holders</td><td className='px-2'>GET</td><td>mint</td>
                                        <td className="text-link">{extensionLink2(`https://api2.tokencheck.io/holders/${token}`, 'https://api2.tokencheck.io/holders/' +  shortAddress(token) )}</td>
                                        <td className='text-yellow-300'>{holdersRequest?.time.toFixed(0)}</td>
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>5</td><td>Request </td><td className='text-orange-500 px-2'> Liquidity</td><td className='px-2'>POST</td>
                                        <td>mint, liquidityA, liquidityB </td>
                                        <td className="text-link">{extensionLink(`https://api3.tokencheck.io/liquidity/`)}</td>
                                        <td className='text-yellow-300'>{liquidityToken?.time.toFixed(0)}</td>
                                        
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>6</td><td>Request </td><td className='text-orange-500 px-2'> tx owner</td><td className='px-2'>GET</td><td>LP, owner</td>
                                        <td className="text-link">{extensionLink(`https://api2.tokencheck.io/tx/owner/LP/owner`)}</td>
                                        <td className='text-yellow-300'>{TxOwner?.time.toFixed(0)}</td>
                                        
                                    </tr>
                                    <tr className="hover:bg-slate-900"><td>7</td><td>Request </td><td className='text-orange-500 px-2'> meta</td><td className='px-2'>GET</td><td>mint</td>
                                        <td className="text-link">{extensionLink2(`https://api2.tokencheck.io/token/request-meta/${token}`, 'https://api2.tokencheck.io/token/request-meta/' + shortAddress(token) )}</td>
                                        <td className='text-yellow-300'>{metaRequest?.time.toFixed(0)}</td>
                                    </tr>

                                    
                                    <tr className="hover:bg-slate-900"><td>8</td><td>Request </td><td className='text-orange-500 px-2'> Dev tokens</td><td className='px-2'>POST</td><td>owner, tokens</td>
                                        <td className="text-link">{extensionLink(`https://api2.tokencheck.io/account/token-amount/${token}`)}</td>
                                        <td className='text-yellow-300'>{TokenDev?.time.toFixed(0)}</td>
                                        
                                    </tr>

                                   <tr><td></td><td></td><td></td><td></td><td></td><td></td><td>{totalTime.toFixed(0)}</td></tr>
                     
                                </tbody>
                                
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      </>
    )
}
  

export default RayToken;