import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import LogoBlack from '../../logo-black.png';
import { ReactComponent as IC_MINT } from '../../ic_mint.svg';
import { ReactComponent as IC_MINT2 } from '../../ic_mint2.svg';
import { ReactComponent as IC_BURN } from '../../ic_burn.svg'; 
import { ReactComponent as MOONSHOT } from '../../moonshot.svg'; 
import { PieChart, Pie, Cell} from "recharts"; //Sector, 
import Footer from '../../components/Footer';

import { shortTokenLink, shortTokenLink2, shortMintAuthorityLink,  varTime, distanceTime,
        solNumber,  getMetaContent, firstWord, amountTransfer, extractTokenCreatorName, 
        typeMint, amountMint, 
        check_dexpaid, checkDexId , beautifyCap, pumpProfile, beautifyPercent,
        percentHolders, graphHolders, rateCreator, externalRaydium, externalDexscreener, realSupply,
        externalMarket, ratioChange, rateTopHolders, rateMintAuthority , rateSPLOwner, showRateAll,
        makePercent, extractSPL, amountToken, showRateDexpaid, showRateMintAuthority,
        getDexMeta } from '../../components/Helper';


/* eslint-disable react-hooks/exhaustive-deps */
const TokenArea = () => {
    const TEST = 0;
    const { mint_address } = useParams();
    
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    
    const [dataMint, setDataMint] = useState(null);
    const [launchTime, setLaunchTime] = useState(0);

    const [isRaydium, setIsRaydium] = useState(1);
    const [isPump, setIsPump] = useState(0);
    const [marketPump, setMarketPump] = useState(null);
    const [marketDex, setMarketDex] = useState(null);

    //info create token 
    const [Supplies, setSupplies] = useState([]); // lấy từ 1 nguồn là database ( TH# thì ko có giá trị này)
    const [TokenSupply, setTokenSupply] = useState(0); // lấy từ 2 nguồn theo 2 trường hợp (database & other)
    const [TokenDecimals, setTokenDecimals] = useState(0); // lấy từ 2 nguồn theo 2 trường hợp (database & other)
    const [isMutable, setIsMutable] = useState(0); // lấy từ 1 nguồn là database nhưng có thể cần request lại meta!

    //mintLP , owner & mint_address --> sẽ lấy dữ liệu cho Dev Area ( Dev và OwnerTx) 
    const [mintLP, setMintLP] = useState(null); // 1TH : Radium db và ? ( vd : RugCheck)
    const [owner, setOwner] = useState(null); // 2 TH : Raydium db và Other (Pump)
    const [instruction, setInstruction] = useState(null); // 1 items của OwnerTx

    //liquidityA liquidityB phục vụ cho Liquidity Area 
    const [liquidityA, setLiquidityA] = useState(null);
    const [liquidityB, setLiquidityB] = useState(null);
    
    //token-meta : mint-tool, web, tele, twitter, description
    const [ContentURI, setContentURI] = useState(null);
    const [TokenCreator, setTokenCreator] = useState(null);

    //name, symbol, image  : có 3 TH lấy được là từ Raydium database, Other Market, Request meta (mint-address)
    const [TokenImage, setTokenImage] = useState(LogoBlack);
    const [TokenName, setTokenName] = useState(null);
    const [TokenSymbol, setTokenSymbol] = useState(null);

    //mint-authority : lấy từ 2 nguồn Database (nếu có sẵn) hoặc request-info ( nếu chưa có hoặc đang active)
    const [mintAuthority, setMintAuthority] = useState(null);

    // thống kê lượng liquidity của token
    const [liquidityToken, setLiquidityToken] = useState({});

    //lấy từ 2 nguồn : từ dexscreener (chuẩn hơn) hoặc từ database 
    const [dexpaid, setDexPaid] = useState(0);
    const [dexMeta, setDexMeta] = useState(null);
    const [moonshot, setMoonshot] = useState(0);



    useEffect(() => {
        if(mint_address !== null){
          loadTokenDataByMint(); 
          loadTokenMetaByMint();
        } 
    }, [mint_address]);

    // dùng cho 2 chỗ
    function parserMainMetaRequest(data){
      if(data == null) return;
      
      if(data.hasOwnProperty("image")) setTokenImage(data["image"]);
      if(data.hasOwnProperty("name")) setTokenName(data["name"]);
      if(data.hasOwnProperty("symbol")) setTokenSymbol(data["symbol"]);

    }

    // function parserMainMetaLoad(data){
    //   if(data == null) return;
      
    //   if(data.hasOwnProperty("token_image")) setTokenImage(data["token_image"]);
    //   if(data.hasOwnProperty("token_name")) setTokenName(data["token_name"]);
    //   if(data.hasOwnProperty("token_symbol")) setTokenSymbol(data["token_symbol"]);
    // }

    function trimSupplies(supplies, decimals){
      if(supplies == null) return null;
      let prev_supply = 0;

      let new_supplies = [];

       for(const sup of supplies){

          if((Math.abs(sup - prev_supply) / sup) > 0.1) {
              const change = realSupply((sup - prev_supply), decimals);
              new_supplies.push(change);
          }

          prev_supply = sup;
      }

      return new_supplies;
      // return html;
    }


    //truy cập bảng token_pair
    function loadTokenDataByMint(){
      fetch('https://api.tokencheck.io/token/mint/' + mint_address)
          .then(response => {
            return response.json(); 
          })
          .then(response => {

            const success = response.success;
            const data  = response.data;

            if(data == null){  // không tìm thấy trong CSDL
                request_market();
                request_info();  
            }
            else{
                //parserMainMetaLoad(data); 
                setIsPump(data.pump);
                setIsRaydium(1);
                setDataMint(data);
                setInstruction(data.instruction);
                //set cho element
                setOwner(data.owner);
                setLiquidityA(data.liquidityA);
                setLiquidityB(data.liquidityB);
                setMintLP(data.mintLP);
                
                setMintAuthority(data["mintAuthority"]);

                setMoonshot(data.moonshot);
                setDexPaid(data.dexpaid);

                if(data.open_time !== 0 ) setLaunchTime(data.open_time);
                else setLaunchTime(data.blockTime);
                
                setTokenSupply(data["supply"]);

                const decimals =  data.decimals; 
                setTokenDecimals(decimals);

                if(data["supplies"] != null && data["supplies"] !== "") {
                  const list_sup = JSON.parse(data["supplies"]);
                  setSupplies(trimSupplies(list_sup, data.decimals));
                }
                
                


                if(data["token_info"] == null) request_info();                     
            } 
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }


    //1. Dùng khi không tìm thấy trong CSDL
    function request_market(){
      fetch("https://api2.tokencheck.io/market/request/" + mint_address)
        .then(response => {
          return response.json(); 
        })
        .then(response => {
            // const success = response.success;
            const data  = response.data;
            if(data != null){
              setDataMint(data);
              parserMainMetaRequest(data.content_uri); // có cần thiết ?
              setLiquidityA(data.liquidityA);
              setLiquidityB(data.liquidityB);
              setOwner(data.owner);
              setIsPump(data.pump);
              //maybe set - isRaydium but load dex solve it
              setTokenSupply(data.supply);
              setTokenDecimals(data.decimals);

              if(data.blockTime) setLaunchTime(data.blockTime);

              //khi nao load meta, info
              if(data.isInfo !== 1){
                  request_info();  
              }

              if(data.isMeta !== 1){
                  request_meta();
              }

              if(data.pair == null){
                  setIsRaydium(-1);
              }

              if(data.pair == null && data.pump === 1){
                 setMarketPump(data.market);
              }

            }
            // else {
            //   request_meta();
            // }
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        }); 
    }

    const [pumpProfit, setPumpProfit] = useState(null);
    useEffect(() => {
      if(isPump === 1 && isRaydium !== 1){
        fetch('https://api2.tokencheck.io/pump/trade/' + mint_address)
            .then(response => {
                return response.json(); 
            })
            .then(response => {

                 const data = response.data;
                 if(data != null) setPumpProfit(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
      }

    }, [isPump, isRaydium]);




    /***
     * Các trường hợp dùng :
     * truy cập bảng token_meta
     */
    function loadTokenMetaByMint(){
      if(TEST === 1) return;

      fetch('https://api.tokencheck.io/token/mint-meta/' + mint_address)
          .then(response => {
            return response.json();
          })
          .then(response => {
            const success = response.success;
            const data  = response.data;

            if(success === 0){
                request_meta();  // khi load db bị lỗi hoặc không tìm thấy phần tử trong database
            }
            else {
                if(data["token_creator"] !=  null && data["token_creator"] !== ""){  // có 2 trươngf hợp : JSON hoặc TEXT (pump.fun)
                    setTokenCreator(JSON.parse(data["token_creator"]));
                }

                if(data["content_uri"] != null){
                    setContentURI(data["content_uri"]);
                    parserMainMetaRequest(data["content_uri"]);
                }

                setIsMutable(data["isMutable"]);

            }
          })
          .catch(error => {
              // Xử lý khi gặp lỗi trong quá trình fetch
              console.log('Fetch Error:', error);
          });
    }

    


    


    /******************************************************************
     * META AREA 
     ******************************************************************/

     //với token mà có thể sửa được info thì rẽ request meta khi click vào link
     useEffect(() => {
        if(isMutable === 1){
            request_meta();
        }
    }, [isMutable]);

    // 1. Dùng khi isMutable = 1 ( token có thể thay đổi)
    // 2. Hoặc khi không tìm thấy trong CSDL ( bảng token_meta)
    // 3. Hoặc khi tìm kiếm ở kênh khác nhưng không có giá trị meta
    function request_meta(){
      if(TEST === 1) return;

      fetch('https://api2.tokencheck.io/token/request-meta/' + mint_address)
          .then(response => {
              return response.json(); 
          })
          .then(response => {
              // const success = response.success;
              const data  = response.data;
                
              if(data != null) {
                  parserMainMetaRequest(data["content_uri"]); // image, name, symbol
                  setTokenCreator(data["token_creator"]); // chứa mint-tool
                  setContentURI(data["content_uri"]); //chứa info như twitter. website. telegram
                  setIsMutable(data["isMutable"]);
              }
                  
          })
          .catch(error => {
            console.log('Fetch Error:', error);
     });
  }


    
    /***********************************************************
     * AREA INFO 
     ***********************************************************/

    useEffect(() => {
      if(mintAuthority != null){
         request_info();
      }
    }, [mintAuthority]);

    //1. Dùng khi ko tìm thấy dữ liệu trong CSDL (token_pair)
    //2. Khi mintAuthority khác null --> thì info có thể thay đổi (mint thêm)
    //3. Khi tồn tại trong token_pair nhưng chưa kip load info về CSDL
    function request_info(){   
        if(TEST === 1) return;

        fetch('https://api2.tokencheck.io/token/request-info/' + mint_address)
            .then(response => {
                return response.json(); 
            })
            .then(response => {
                // const success = response.success;
                const data  = response.data;
                  
                if(data != null) {

                    setTokenSupply(data?.supply);

                    setTokenDecimals(data?.decimals);

                    setMintAuthority(data?.mintAuthority);
                }
               
            })
            .catch(error => {
                console.log('Fetch Error:', error);
        });
    }





    /***************************************************************
    *  LIQUIDITY AREA : lấy giá trị dựa vào liquidityA, liquidityB
    ***************************************************************/
    useEffect(() => {
          const _check_liquid_loop  = check_liquid_loop();
          if(_check_liquid_loop){
              token_liquidity().finally(() => {
                    setInterval(() => {
                        token_liquidity();
                    }, 10000);
              });
          }
          else token_liquidity();
    
      // return () => clearInterval(intervalId);
    }, [liquidityB, liquidityA]);

    function check_liquid_loop(){
        // if(dataMint == null || dataMint.remove === 1 ) return false;
        // return true;
        return false;
    }

    //load liquidity khi có liquidityA và B
    async function token_liquidity(){
      if(TEST === 1) return;

      setLiquidityToken({});
      if(liquidityB != null && liquidityA != null){
          var params = {liquidityB : liquidityB , liquidityA : liquidityA, mint : mint_address, index : 0, page: 0};
          // console.log(params);
          fetch('https://api3.tokencheck.io/liquidity/',
                  {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(params)
                  }
              )
              .then(response => {
                  return response.json(); 
              })
              .then(response => {
                  // const success = response.success;
                  const data  = response.data;
                  if(data!=null) setLiquidityToken(data);

              })
              .catch(error => {
                console.log('Fetch Error:', error);
              });
        }
    }



    
    /************************************************************
     * DEV AREA : 1 khu vực phức tạp 
     ************************************************************/
    const [TokenDev, setTokenDev] = useState([]);
    const [TxOwner, setTxOwner] = useState(null);
    const [SPLDev, setSPLDev] = useState(0);
    const [MintDev, setMintDev] = useState(0);
    

    useEffect(() => {
      if(mintLP && TokenDev) setSPLDev(realSupply(amountToken(TokenDev, mintLP), TokenDecimals));
    },[TokenDev, mintLP, TokenDecimals]);

    useEffect(() => {
        if(TokenDev) setMintDev(realSupply(amountToken(TokenDev, mint_address), TokenDecimals));
        
    },[TokenDev,mint_address, TokenDecimals ]);


    useEffect(() => {
      if(owner != null ){ 
          requestTokenByDev(owner, mint_address, mintLP);
      }
    }, [owner, mintLP]);


    //Cập nhật số lượng token và LP mà dev đang giữ!
    function requestTokenByDev(owner_, mint_address_, mintLP_ ){
      if(TEST === 1) return;
            
      let url;
      if(mintLP_ != null && mintLP_ !== ''){
          url = 'https://api2.tokencheck.io/account/token-amount/' + owner_ + "/" + mint_address_ + "," + mintLP_;
      }
      else  url = 'https://api2.tokencheck.io/account/token-amount/' + owner_ + "/" + mint_address_;

      fetch(url)
        .then(response => {
            return response.json(); 
        })
        .then(response => {
            // const success = response.success;
            const data  = response.data;
            if(data != null) setTokenDev(data); 
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        });
    }

    
    useEffect(() => {
      if(owner !== null){
        loadTxOwner(mint_address, owner);
      }
      
    }, [mint_address, owner]);

    // truy cập bảng tx_owner
    //1. Dùng khi owner khác null --> kiểm tra bảng tx_owner
    function loadTxOwner(_mint, _owner){
        if(TEST === 1) return;

        fetch('https://api.tokencheck.io/token/tx-owner/' + _mint + "/" + _owner)
            .then(response => {
                return response.json();
            })
            .then(response => {
                const success = response.success;
                const data  = response.data;
                if(success === 1) setTxOwner(data["tx_owner"]);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }



    useEffect(() => {
      // const intervalId = setInterval(() => {
        if(mintLP != null && owner != null){
            requestLPTranx(mintLP, owner);
        }

      // }, 5000);
    }, [ mintLP, owner]);


    
    


    function check_dev_loop(){
      if(dataMint == null || dataMint.remove === 1) return false;
      // check SPL in dev

      return true;
    }

    //1. tìm kiếm tranx có thể làm remove/ mint từ LP address
    function requestLPTranx(_mintLP, _owner){
        if(TEST === 1) return;

        fetch('https://api2.tokencheck.io/tx/owner/' + _mintLP + "/" + _owner)
            .then(response => {
                return response.json();
            })
            .then(response => {
                // const success = response.success;
                const data  = response.data;
                if(data != null) setTxOwner(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }



    /***************************************************************
     * AREA : TOP HOLDERS : START 
     ***************************************************************/
    const [TokenOwners, setTokenOwners] = useState(null);

    useEffect(() => {
      if(mint_address != null){ 
          requestTopHolders(mint_address);
      }
    }, [mint_address]);
  

    function requestTopHolders(mint_address_){
        if(TEST === 1) return;

        fetch('https://api2.tokencheck.io/holders/' + mint_address_)
            .then(response => {
                return response.json(); 
            })
            .then(response => {
                const success = response.success;

                if(success === 1){
                    const data  = response.data;
                    setTokenOwners(data);
                }
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }


    /************************************************************
     *  RATE : TOKEN
     * *********************************************************/
    const [rateAll, setRateAll] = useState(null);
    const [SPLDevPercent, setSPLDevPercent] = useState(0);
    const [mintDevPercent, setMintDevPercent] = useState(0);
    const [RemovePercent, setRemovePercent] = useState(0);
    const [HolderPercent, setHolderPercent] = useState(null);
    

    useEffect(() => {
      if(mintLP && TokenDev) setSPLDevPercent(makePercent(amountToken(TokenDev, mintLP) , extractSPL(instruction)));

    },[TokenDev, mintLP, instruction ]);

    useEffect(() => {
        if(TokenDev) setMintDevPercent(makePercent(amountToken(TokenDev, mint_address) , TokenSupply));
    },[TokenDev, mint_address,TokenSupply ]);
    

    function style_warning_percent(SPLDevPercent){
        if(Number(SPLDevPercent) > 0) return 'color-alarm';
        else return '';
    }
    
    useEffect(() => {
      if(instruction != null){
          var total_spl = extractSPL(dataMint.instruction);
          var total_burn_spl = 0;
          TxOwner?.map((item, index) => {
              // const type_mint = typeMint(item.instructions);
              // console.log(item.type);
              if(item.type === "remove-liquid"){
                  const amount_mint = amountMint(item.instructions);
                  total_burn_spl += amount_mint;
              }

              return null;
          });

          if(total_spl !== 0){
            const ratio_burn = 100* total_burn_spl / total_spl;
            setRemovePercent(ratio_burn.toFixed(0));
          }
      }

    },[TxOwner, dataMint]);  

    useEffect(() => {
        setHolderPercent(percentHolders(TokenOwners, TokenSupply, dataMint?.liquidityA));
    },[TokenOwners, TokenSupply, dataMint ]);

    useEffect(() => {
      const _rateAll = showRateAll(dataMint, mintAuthority,SPLDevPercent, RemovePercent);
      setRateAll(_rateAll);

    },[dataMint,mintAuthority, SPLDevPercent, RemovePercent, TxOwner ]);
    


    /************************************************************
     *  REQUEST DEXSCREENER
     * *********************************************************/

    function dex_banner(mint_address_){
        if(mint_address_)
          return 'https://dd.dexscreener.com/ds-data/tokens/solana/' + mint_address_ + '/header.png';
        else return null;

    }

    useEffect(() => {
      if(mint_address != null){

        fetch('https://api.dexscreener.com/latest/dex/tokens/' + mint_address)
            .then(response => {
                return response.json(); 
            })
            .then(data => {

                const raydium = checkDexId(data, "raydium");
                if(raydium != null) {
                  setIsRaydium(1);
                  setMarketDex(raydium);
                  setDexMeta(getDexMeta(raydium));
                }


                const moonshot = checkDexId(data, "moonshot");
                if(moonshot != null){
                    setMoonshot(1);

                    if(raydium == null){ // chỉ xét dến khi raydium = null
                      setMarketDex(moonshot);
                      setDexMeta(getDexMeta(moonshot));
                    }
                }


                setDexPaid(check_dexpaid(data));

            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
      }  
    }, [mint_address]);


    

    function renderDexpaid(){
        if(moonshot === 1) return;

        if(dexpaid === 1) return (    <>
                                        <div className='text-green-500'>Paid <i className="fa-solid fa-square-check"></i></div>
                                        <img className='mt-4' src={dex_banner(mint_address)} alt="No banner"/>
                                      </>);
        else return <div className="text-red-600 font-bold align-top">No</div>
    }


    


    return (
        <div className="container mx-auto p-4">
             <div className="w-full md:flex items-center">
                <div className="w-full md:w-1/3 flex items-center max-sm:mb-4">

                  <img src={TokenImage} 
                      className="token-image mr-2" alt="" />
                  <div>
                    <div className="text-xl break-all">{TokenName}</div>
                    <div className="text-md">{TokenSymbol}</div>
                    <div className='text-md text-yellow-200'>{varTime(launchTime)}</div>
                  </div>
                  
                </div>
                <div className="w-full md:w-1/3 flex max-sm:mb-4">
                  <div className="w-full text-center">
                    
                    <div className="x-board  rounded-md mt-2 flex relative">
                        <div className="absolute left-4">LIQUIDITY</div>
                        <div className="mx-auto w-full"><span className="color-good">{solNumber(liquidityToken?.liquidityBAmount)} </span> SOL 
                                    <i className="fa-solid fa-arrow-right-arrow-left ml-2 mr-2 text-slate-400"></i>
                                    <span>{makePercent(liquidityToken?.liquidityAAmount , TokenSupply)}%</span> Token
                        </div>
                        <div className="absolute right-4"> { ratioChange(liquidityToken?.liquidityBAmount, dataMint?.init_pc_amount) } </div>
                    </div>
                    {/* <div className="px-2"><div className="bg-blue-700 rounded transition-all duration-[5000ms]" style={{ width: `${progress}%` , height : "1px" }}></div></div> */}
                    {
                        marketPump?(
                            <div className="x-board  rounded-md mt-2 flex relative text-sm">
                              <div className="absolute left-4"><img className='w-5' src="https://pump.fun/_next/image?url=%2Flogo.png&w=32&q=75"/></div>
                              <div className="mx-auto w-full">
                                    MC : <span className='text-green-300'> {beautifyCap(marketPump?.usd_market_cap)}</span>
                                    <span className='ml-2 mr-2'><i class="fa-regular fa-comments"></i> {marketPump?.reply_count}</span>
                                    {
                                      marketPump.king_of_the_hill_timestamp?(
                                         <span className="text-yellow-300"><i class="fa-solid fa-crown"></i> {varTime(marketPump.king_of_the_hill_timestamp / 1000)}</span>
                                      ):("")
                                    }
                              </div>
                          </div>):("")
                    }

                    {
                      marketDex?(
                          <div className="x-board  rounded-md mt-2 flex relative text-sm">
                              <div className="absolute left-4">
                                  {
                                    isRaydium == 1?(<img className='w-5' src="https://dd.dexscreener.com/ds-data/dexes/raydium.png"/>)
                                    :(<MOONSHOT/>)
                                  }
                                </div>

                              <div className="mx-auto w-full gap-2">
                                  MC : <span className='text-green-300'> {beautifyCap(marketDex?.fdv)}</span>

                                  <span className='ml-2 mr-2'>5' {beautifyPercent(marketDex?.priceChange?.m5)}</span>
                                  <span>1h {beautifyPercent(marketDex?.priceChange?.h1)}</span>
                                  <span className='ml-2'>24h {beautifyPercent(marketDex?.priceChange?.h24)}</span>
                              </div>
                          </div>
                      ):("")

                    }
                    

                    <div className="mt-2 flex gap-4 justify-center">{rateAll}
                      {showRateDexpaid(dexpaid)}
                      
                      {showRateMintAuthority(dataMint?.mintAuthority)}
                    </div>

                  </div>
                </div>
                <div className="w-full md:w-1/3  items-center">
                    <div className="md:float-right justify-center flex max-sm:mb-4">
                        <div>{externalDexscreener(mint_address, marketDex)}</div>
                        <div>{externalRaydium(mint_address, isRaydium)}</div>
                        <div>{externalMarket(mint_address, TokenCreator, isPump)}</div>
                    </div>
                </div>
             </div>

             <div className="w-full flex mt-4">
                <div className="w-full grid grid-cols-2 max-sm:grid-cols-1 md:space-x-4 max-sm:space-y-4">
                    <div>
                        <div className="x-board rounded-md justify-center p-4 relative">
                            <div className="absolute md:right-4 md:top-4 max-sm:right-2 max-sm:top-2"></div>
                            <table className="table-auto w-full">
                              <tbody>
                                  <tr><td className='w-32'>Token</td><td>{shortTokenLink(mint_address , "token")} </td></tr>
                                  <tr><td className='w-32'>Supply</td><td>{realSupply(TokenSupply, TokenDecimals)}</td><td></td></tr>
                                  <tr><td className='w-32 align-top'>Mint Authority</td><td>{shortMintAuthorityLink(mintAuthority, "token")} 
                                        <span className='float-right'>{rateMintAuthority(mintAuthority)}</span>
                                  </td>
                                  </tr>
                                  {
                                    (Supplies.length > 1)?(
                                        <tr><td className='w-32 align-top'>Mint+</td>
                                            <td className='color-normal'>{
                                              Supplies.map((sup, index) => {
                                                   return (index === Supplies.length - 1)?
                                                      (<span>{sup}</span>)
                                                    : (<span>{sup}<i className="fa-solid fa-arrow-right ml-2 mr-2"></i></span>)
                                              })
                                            }
                                          </td>
                                        </tr>
                                    ):(<></>)
                                    
                                  }

                                  <tr><td className='w-32 align-top'>SPL Token</td><td>{shortTokenLink2(mintLP, "token")}</td></tr> 
                                  <tr><td className='w-32 align-top'>Mint Tool</td><td>{extractTokenCreatorName(TokenCreator)}
                                      <span className='float-right'>{rateCreator(TokenCreator, isPump)}</span>
                                  </td></tr> 
                              </tbody>
                          </table>
                        </div>

                        <div className="x-board rounded-md justify-center mt-4 p-4">
                          <table className="table-auto w-full">
                              <tbody>
                                  
                                  <tr><td className='w-32 align-top'>Web</td><td><div className='break-all'>{getMetaContent("website", ContentURI, dexMeta )}</div></td></tr>
                                  <tr><td className='w-32 align-top'>Tele</td><td><div className='break-all'>{getMetaContent("telegram",ContentURI, dexMeta )}</div></td></tr>
                                  <tr><td className='w-32 align-top'>Twitter</td><td><div className='break-all'>{getMetaContent("twitter",ContentURI , dexMeta )}</div></td></tr>
                                  <tr><td className="w-32 align-top">Description</td><td><div className='break-all whitespace-break-spaces'>{getMetaContent("description",ContentURI )}</div></td></tr>
                                  
                              </tbody>
                          </table>
                        </div>


                        <div className="x-board rounded-md justify-center mt-4 p-4">
                          <table className="table-auto w-full">
                              <tbody>
                                  <tr><td className='w-32 align-top'>Dexscreener <br/> Promote</td>
                                      <td className="dexscreener-ads">
                                        {
                                            (moonshot === 1)?(<div className='text-moonshot'>Moonshot</div>):("") 
                                        }
                                        
                                        {renderDexpaid()}
                                      
                                      </td>

                                      
                                  </tr>
                                 
                              </tbody>
                          </table>
                        </div>
                    </div>


                    <div>
                    
                     
                    <div className="x-board rounded-md justify-center p-4  mb-4 relative">
                      {/* <div className="absolute md:right-4 md:top-4 max-sm:right-2 max-sm:top-2">{rateSPLOwner(SPLDevPercent)}</div> */}
                        <table className="table-auto w-full"> 
                            <tbody>
                              <tr key="dev">
                                <td className="align-top"><div className="md:flex w-full">
                                      <div className="mr-4">Dev</div>
                                      <div className="md:float-right">{shortTokenLink2(owner, "account")}</div>
                                    </div>
                                </td>
                                
                                <td className='align-top'>
                                    <div>{MintDev} <span className='text-slate-400'>Token</span></div>
                                    <div>{SPLDev} <span className='text-slate-400'>SPL</span></div>
                                </td>

                                <td className='align-top flex'>
                                  <div>
                                    <div>{mintDevPercent + "%"} </div>
                                    <div className={style_warning_percent(SPLDevPercent)}>{mintLP?SPLDevPercent + "%" :""}</div>
                                  </div>
                                  <div className="w-full">
                                        <div className='md:float-right'>{rateSPLOwner(SPLDevPercent)}</div>
                                  </div>
                                </td>
                              </tr>
                              
                              { dataMint?.txLiquid?(
                                <tr key="pool" className='border-t-8 border-transparent'>
                                    <td className='align-top'><div className="md:flex w-full">
                                          <div className="mr-4">Pool</div>
                                          <div className="md:float-right">{shortTokenLink2(dataMint?.txLiquid, "tx")}</div>
                                        </div>
                                    </td>
                                  
                                  <td><div>
                                          <span className="color-good">{solNumber (dataMint?.init_pc_amount)}</span> SOL
                                          <i className="fa-solid fa-arrow-right-arrow-left ml-2 mr-2 text-slate-400"></i>
                                          {realSupply(dataMint?.init_coin_amount, dataMint?.decimals)} <span className='text-slate-400'>Token</span>
                                      </div>
                                      <div>
                                          {
                                            dataMint?.instruction?
                                              (
                                                <div className="w-full flex">
                                                  <div className="mr-2"><IC_MINT/></div>
                                                  <div className="mr-2"> {realSupply(extractSPL(dataMint?.instruction) , dataMint?.decimals)} <span className='text-slate-400'>SPL</span> </div>
                                                </div>  
                                              )
                                              : ""
                                          }
                                      </div>
                                      <div>
                                        {
                                          (dataMint.burn_block === 1)?
                                              (
                                                <div className="w-full flex">
                                                  <div className="mr-2"><IC_BURN/></div>
                                                  <div className="mr-2"> 100%</div>
                                                </div>  
                                              )
                                              : ""
                                        }

                                      </div>
                                    
                                  </td>

                                  <td className="align-top">
                                      <div className="md:flex">
                                          <div className="mr-4">
                                                <div>{makePercent(dataMint?.init_coin_amount , dataMint?.supply) + "%"} </div>
                                                <div className='text-slate-400'>Token</div>
                                          </div>
                                          <div className="w-full">
                                              <div className="md:float-right">{varTime(dataMint?.open_time, dataMint?.blockTime)}</div>
                                          </div>
                                      </div>
                                  </td>

                                </tr>
                              )
                              :(<></>)
                              } 


                              {
                                TxOwner?.map((item, index) => {
                                    
                                    if(item != null) 
                                    {
                                      if(item.signature === dataMint?.txLiquid || !item.hasOwnProperty("type")) return;


                                      const amountA = amountTransfer(item.instructions, dataMint.liquidityA);
                                      const amountB = amountTransfer(item.instructions, dataMint.liquidityB);
                                      const amount_mint = amountMint(item.instructions);
                                      var type_mint = typeMint(item.instructions);
                                      if(type_mint === "burnChecked") type_mint = "burn"; //mint. transfer. burn 

                                      const type = item.type ;// remove-liquid....
                                      
                                      return (<tr key="txOwner_{index}" className='border-t-8 border-transparent'> 
                                                  <td className='align-top'><div className="md:flex w-full">
                                                        <div className="mr-4">{firstWord(item.type)}</div>
                                                        <div className="md:float-right">{shortTokenLink2(item.signature, "tx")}</div>
                                                      </div>
                                                  </td>                                                  
                                                  <td className='align-top'>
                                                     {
                                                        (type === "remove-liquid")?(                                                     
                                                            <div className="w-full flex items-center gap-1">
                                                                        {realSupply(amountA,TokenDecimals)} <span className='text-slate-400'> Token</span>
                                                                        { amountA?(<i className="fa-solid fa-arrow-right-arrow-left ml-2 mr-2 text-red-600"></i>): ""}
                                                                        { solNumber(amountB) } SOL
                                                            </div>
                                                        ):("")
                                                      }

                                                      <div className="w-full flex items-center">
                                                          {
                                                            (type_mint === "mintTo")?(<div className="mr-2"><IC_MINT2/></div>): (<div className="mr-2"><IC_BURN/></div>)
                                                          }
                                                          
                                                          <div>{realSupply(amount_mint, TokenDecimals)} <span className='text-slate-400'>SPL</span></div>
                                                      </div>

                                                  </td>
                                                  <td className='align-top'><div className="md:flex">
                                                        <div className="w-full"><div className="md:float-right text-slate-400">+{distanceTime(item.blockTime, dataMint?.blockTime)}</div></div>
                                                      </div>
                                                  </td>

                                              </tr>
                                    
                                      )
                                      

                                    }
                                    else return null;
                                  
                              })}
                              
                              
                          </tbody>
                        </table>

                        
                    </div>
                    
                    
                    {
                      pumpProfit?(
                          <div className="x-board rounded-md justify-center pl-4 pr-4 pb-4">
                                  <div className='w-full text-center text-highlight underline underline-offset-4 mt-2 mb-2'>Pump Profit (SOL)</div>
                                  <table className="table-auto w-full">
                                        <tr><td>No</td><td>User</td><td>Wallet</td><td>Buy</td><td>Sell</td><td>Profit</td></tr>
                                      {
                                        pumpProfit.map((item, index) => {
                                            if(index >= 10) return;
                                            return (
                                              <tr key={index}><td>{index+1}</td>
                                                  <td>{pumpProfile(item.username, item.user) }</td>
                                                  <td>{shortTokenLink2(item.user, "account")}</td>
                                                  <td>{solNumber(item.sol_buy)}</td>
                                                  <td>{solNumber(item.sol_sell)}</td>
                                                  <td>{solNumber(item.sol_sell - item.sol_buy)}</td>
                                              </tr>
                                            )
                                        })
                                      }
                                  </table>
                            </div>
                      ):("")
                    }

                          

                    <div className="x-board rounded-md justify-center mt-4 p-4 min-h-40 flex relative">
                          {/* <div className="absolute md:right-4 md:top-4 max-sm:right-2 max-sm:top-2">{rateTopHolders(HolderPercent)}
                                
                          </div> */}
                          <table className="table-auto w-1/2">
                            <thead>
                                <tr><td>Top Holders</td></tr>
                            </thead>

                              <tbody>
                                {
                                  TokenOwners?.map((item, index) => {
                                      if(index < 10){
                                        var plus = "";
                                        if(dataMint){
                                          if(item.address === dataMint.mintAccount) plus = "(Dev)";
                                          else if(item.address === dataMint.liquidityA) plus = "(Pool)";
                                        }
                                  
                                        
                                        return (
                                          <tr key={index}><td>{shortTokenLink2(item.address, "account")} <span>{plus}</span></td><td>{ makePercent(item.amount , TokenSupply) + "%"}</td></tr>
                                        )
                                      }
                                      else return null;
                                     
                                  }
                                )}
                              </tbody>
                          </table>   

                          <div className="w-1/2">
                                <PieChart className="mx-auto max-sm:p-4" width={200} height={200}>
                                      <Pie
                                        data={graphHolders(TokenOwners, TokenSupply)}
                                        cx={100}
                                        cy={100}
                                        innerRadius={50}
                                        outerRadius={80}

                                        // labelLine={false}
                                        // label={renderCustomLabel}

                                        fill="#8884d8"
                                        paddingAngle={3}
                                        dataKey="value"
                                      >
                                        {graphHolders(TokenOwners, TokenSupply)?.map((entry, index) => (
                                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                      </Pie>
                                  </PieChart>
                            

                              <div className='text-center'>
                                  <div>Top 10 Holders (Exclude Pool)</div>
                                  <div>{HolderPercent} %
                                        <span className='float-right'>{rateTopHolders(HolderPercent)}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>

                    
                </div>
                

             </div>

             <Footer/>
            
        </div>
    )
}

export default TokenArea