import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import ToolHead from '../tool-api/ToolHead';
import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import {extensionLink, varTime} from '../../components/Helper';

/* eslint-disable react-hooks/exhaustive-deps */
function Tx() {
    const {transaction } = useParams();

    const [tx, setTx] = useState(null);
    const [data, setData] = useState(null);
   

    useEffect(() => {
        if(transaction != null) setTx(transaction);
    },[transaction]);

    
    useEffect(() => {
        if(tx != null) {
            handleLoadDB();
        }
    },[tx]);


    const handleMintChange = (e) => {
        const _tx = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', _tx);
        setTx(_tx);
    }

    const handleLoadDB = () => {
        if(tx !== ""){
            fetch('https://tool.tokencheck.io/tx/instruction-type/' + tx)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                
                    setData(data);
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });
        }
    }

    return (
      <><Header/>
        <div className="p-4 mx-auto">
            <ToolHead/>
            <div className="w-full mt-4 md:flex">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                <div className='w-full x-board p-4'>
                    <div className='items-center text-center'>
                        <div>TX</div>
                        <div><input className="w-full p-1.5 text-black"    
                                                defaultValue={tx}     
                                                onChange={handleMintChange}  
                                                type="text"/>
                        </div>
                    </div>
                    <div className="flex mt-10 gap-4">
                        <div className='w-1/2'>
                            <div className='text-orange-500'>Load Types : {data?varTime(data.blockTime):""}</div>
                            <div className='text-xs truncate'>{extensionLink(`https://tool.tokencheck.io/tx/instruction-type/${tx}`)}</div>
                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                <pre>
                                    {
                                        data?JSON.stringify(data,undefined, 2):""
                                    }
                                </pre>
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <div className='text-orange-500'>Load Account</div>
                            <div className='text-xs truncate'>{extensionLink(`https://tool.tokencheck.io/tx/account/${tx}`)}</div>

                            <div className='text-orange-500'>Load Log</div>
                            <div className='text-xs truncate'>{extensionLink(`https://tool.tokencheck.io/tx/log/${tx}`)}</div>

                            <div className='text-orange-500'>Load Instruction</div>
                            <div className='text-xs truncate'>{extensionLink(`https://tool.tokencheck.io/tx/instruction/${tx}`)}</div>
                        </div>
                    </div>
                </div>
               
                </div>
            </div>
        </div>
      </>
    )
}
  

export default Tx;