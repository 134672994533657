import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function AnalysisHead() {
    const [activeItem, setActiveItem] = useState('analysis'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        if (location.pathname === '/analysis/') {
          setActiveItem('analysis');
        } 
        else if (location.pathname === '/analysis-raydium/'){
          setActiveItem('analysis-raydium');
        }
        else if (location.pathname === '/analysis-pump-king/'){
            setActiveItem('analysis-pump-king');
        }
        else if (location.pathname === '/analysis-pump-new/'){
            setActiveItem('analysis-pump-new');
        }
        else if (location.pathname === '/analysis-game/'){
            setActiveItem('analysis-game');
        }
       
      }, [location]); // update when location changes

      const handleMenuClick = (event, path) => {
        event.preventDefault();
        if (location.pathname === path) {
            window.location.reload();
        } else {
            window.location.href = path;
        }
    };


    return (
        //navigation
        <div className='flex justify-center mt-4 gap-4'>
            <div>
                <button onClick={(e) => handleMenuClick(e, '/analysis/')} className={activeItem === 'analysis' ? 'text-highlight cursor-pointer' : 'cursor-pointer'}>DAILY</button>
            </div>

            <div>
                <button onClick={(e) => handleMenuClick(e, '/analysis-pump-king/')} className={activeItem === 'analysis-pump-king' ? 'text-highlight cursor-pointer' : 'cursor-pointer'}>PUMP KING</button>
            </div>

            <div>
                <button onClick={(e) => handleMenuClick(e, '/analysis-pump-new/')} className={activeItem === 'analysis-pump-new' ? 'text-highlight cursor-pointer' : 'cursor-pointer'}>PUMP NEW</button>
            </div>

            <div>
                <button onClick={(e) => handleMenuClick(e, '/analysis-raydium/')} className={activeItem === 'analysis-raydium' ? 'text-highlight cursor-pointer' : 'cursor-pointer'}>RAYDIUM</button>
            </div>

            <div>
                <button onClick={(e) => handleMenuClick(e, '/analysis-game/')} className={activeItem === 'analysis-game' ? 'text-highlight cursor-pointer' : 'cursor-pointer'}>GAME</button>
            </div>

           
        </div>
    );

}
