import React, { useState, useEffect } from 'react';
import {shortAddress,  varTime,  solNumber, ratioChange, makePercent, showRateToken, showRateMintAuthority, showRateDexpaid } from '../../components/Helper';
import Modal from '../../components/wallet/Modal';
import { useLocation, useHistory } from 'react-router-dom';
import Navigation2 from '../../components/header/Navigation2';

/* eslint-disable react-hooks/exhaustive-deps */
const TokensArea = () => {
    const TEST = 0;
    const [page, setPage] = useState(1);
    const nextPage = () => {setPage(page + 1)};
    const prevPage = () => {if(page > 1) setPage(page - 1)};

    const [dataList, setDataList] = useState([]);
    const [filter, setFilter] = useState({});


    /*******************************************
    * AREA : MODAL / OPEN LINK
    *******************************************/
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {setIsModalOpen(true);}
    const closeModal = () => {setIsModalOpen(false);}

    let history = useHistory();
    const openLink = (event, mintToken) => {
      if (event.button === 1) { 
          window.open('/token/' + mintToken, '_blank');
      }
      else  history.push('/token/' + mintToken);

      event.preventDefault(); // Prevent default behavior (if any)
    }

    const doubleClick = (event, mintToken) => {
      history.push('/token/' + mintToken);
      event.preventDefault(); // Prevent default behavior (if any)
    }

    /*******************************************
     * AREA : SEARCH
     *******************************************/
    const [search, SetSearch] =  useState(null);
    const [checkSearch, setCheckSearch] = useState(false);
    let location = useLocation();

    
    
    useEffect(() => {
      // Kiểm tra xem URL có tham số 's' không
      const searchParams = new URLSearchParams(location.search);
      const s = searchParams.get('search');
      if (s) {
        SetSearch(s);
        setFilter({ ...filter, "search" : s});
      }
      setCheckSearch(true);

    }, []);

    /*******************************************
     * AREA : FILTER 
     *******************************************/

    const [checkedItems, setCheckedItems] = useState({
      pinksale: false,
      pumpfun: false,
      moonshot: false,
    });

    const handleChange = (event) => {
        const { name, checked } = event.target;
       
        setPage(1);

        setCheckedItems({
          ...checkedItems,
          [name]: checked,
        });
        

        if(checked){
          setFilter({ ...filter, presale : name});

          var new_filter = {pinksale: false, pumpfun: false, moonshot: false};
          new_filter[name] = true;
          setCheckedItems(new_filter);
        }
        else {
          setFilter({});
        }
        // openModal();
    };


    const [next, setNext] = useState(false);
    const handleNext = (event) => {
      const { checked } = event.target;
      setNext(checked);
      setFilter({ ...filter, next : checked});
    }

    const [burn, setBurn] = useState(false);
    const handleBurn = (event) => {
      const { checked } = event.target;
      setBurn(checked);
      setFilter({ ...filter, burn: checked});
    }

    const [dexpaid, setDexpaid] = useState(false);
    // dex paid
    const handleDexpaid = (event) => {
      const { checked } = event.target;
      setDexpaid(checked);
      setFilter({ ...filter, dexpaid : checked});
    }

    /*******************************************
     * AREA : LOAD DB 
     *******************************************/

   
    useEffect(() => {
        if(checkSearch === false) return;

        loadTokens();
      
    }, [page, filter, checkSearch]);

  function loadTokens(){
      setDataList([]);

      const limit = 15;
      const offset = limit * (page-1);
      var params = {"offset" : offset , "limit" : limit , "sort" : "blockTime", "order" : "DESC"};

      params["filter"] = filter;

      fetch('https://api.tokencheck.io/token/pair',
          {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(params)
          }
        )
        .then(response => {
          return response.json(); 
        })
        .then(response => {
          // const success = response.success;
          const data  = response.data;
          if(data != null) setDataList(data);                
        })
        .catch(error => {
          console.log('Fetch Error:', error);
        });
  }


  /*******************************************
   * AREA : REQUEST LIQUIDITY LIST
   *******************************************/

  const [items, setItems] = useState({});

  // const addItem = (key, value) => {
  //   setItems(prevItems => ({ ...prevItems, [key]: value }));
  // };

  // const removeItem = (key) => {
  //   const { [key]: _, ...rest } = items;
  //   setItems(rest);
  // };

  const updateItem = (key, newValue) => {
    setItems(prevItems => ({ ...prevItems, [key]: newValue }));
  };

  const getItem = (key) => {
    if(items) return items[key];
  };


  useEffect(() => {
    token_liquidity();
  }, [dataList]);

  async function token_liquidity(){
    if(TEST === 1) return;
    if(dataList != null){
        for(const index in dataList){
            const item = dataList[index];
            fetch_liquidity(item, index);
        }
      }
  }

  function fetch_liquidity(item, index){
      var params = {liquidityB : item.liquidityB ,  mint : item.mintA , index : index, page : page}; //liquidityA : item.liquidityA,
      console.log(params);
      fetch('https://api3.tokencheck.io/liquidity/',
          {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(params)
          }
      )
      .then(response => {
          return response.json(); 
      })
      .then(response => {
          // const success = response.success;
          const data  = response.data;

          if(data != null) updateItem(item.liquidityB, data);
      })
      .catch(error => {
        console.log('Fetch Error:', error);
      });
  }

  function getLiquiditySOL(liquidityB){
    if(liquidityB != null &&  items != null) {
      const data = getItem(liquidityB)
      return data?solNumber(data.liquidityBAmount) : "-";
    }

    return "-"
  }

  function getLiquidityBAmount(liquidityB){
    if(liquidityB != null &&  items != null) {
      const data = getItem(liquidityB);
      return data?.liquidityBAmount;
    }

    return 0;
  }


  return (
      <div className="container mx-auto md:mt-4 max-sm:p-4">
          <div className="md:hidden flex justify-center mb-4 gap-4">
              <Navigation2/>
          </div>
          {/* Filter Options */}
          <div className="md:flex gap-4" key="filter">
              <div className="max-sm:w-full x-board px-2 py-1 flex gap-2">
                  <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="pinksale"
                      checked={checkedItems.pinksale}
                      onChange={handleChange}

                    />
                    Pinksale
                  </label>

                  <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="pumpfun"
                      checked={checkedItems.pumpfun}
                      onChange={handleChange}

                    />
                    Pump
                  </label>

                  <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="moonshot"
                      checked={checkedItems.moonshot}
                      onChange={handleChange}

                    />
                    Moonshot
                  </label>
            </div>

            <div className="max-sm:w-full x-board px-2 py-1 flex gap-2">
                <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="burn"
                      checked={burn}
                      onChange={handleBurn}

                    />
                    Burn
                </label>

                <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="dexpaid"
                      checked={dexpaid}
                      onChange={handleDexpaid}
                    />
                    DexPaid
                </label>


                {/* <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="next"
                      checked={next}
                      onChange={handleNext}

                    />
                    Next
                </label> */}
            </div>
          </div>
          {/* Table - token list */}
          <div className="overflow-x-auto	pb-10">
            <table className="table-auto w-full  mt-4 table-tokens" key="list">
              <thead> 
                  <tr>

                    <th className="border border-gray-600 x-2 py-2 sticky left-0  z-10 bg-main">IC</th>
                    <th className="border border-gray-600 py-2 max-sm:hidden">Name</th>
                    <th className="border border-gray-600 py-2 max-sm:hidden">Symbol</th>

                    <th className="border border-gray-600 py-2">C/A</th>
                    
                    <th className="border border-gray-600 py-2" colSpan={2}><div className='md:flex items-center justify-center'><div>Launch Pool</div><div className="text-xs md:ml-2 text-slate-400">(SOL %Supply)</div> </div></th>
                    
                    <th className="border border-gray-600 py-2"><div className='md:flex items-center justify-center'><div>Liquidity</div><div className="text-xs md:ml-2 text-slate-400">(SOL)</div> </div></th>
                    <th className="border border-gray-600 py-2">Rate</th>
                  </tr>
              </thead>
              <tbody className='x-board'>
                {dataList.map((item, index) => {

                          return (

                            
                              <tr key={index} className="max-sm:align-top hover:bg-slate-900 cursor-pointer x-border tr-token" onDoubleClick={(event) => {doubleClick(event, item.mintA)}} onMouseDown={(event) => {openLink(event, item.mintA)}} >
                                    
                                    <td className="px-2 py-2  left-0  max-sm:sticky max-sm:z-10 bg-stick">
                                        <img src={item.token_image} className="h-8 w-8 rounded-full border-2 md:h-10 md:w-10 border-rebrand-chain-solana" alt={item.token_name} />
                                        <div className="absolute right-[-3px] bottom-[-3px] flex rounded-full bg-black p-[1px]">
                                            <img src="/assets/images/exchanges/orca.png" alt="orca" className="h-[15px] w-[15px] rounded-full bg-white sm:h-[18px] sm:w-[18px]"/>
                                        </div>
                                    </td>
                                    <td className="max-sm:px-2 max-sm:py-2 md:px-4 whitespace-nowrap">{item.token_name}</td>
                                    <td className="max-sm:px-2 max-sm:py-2 md:px-4 max-sm:hidden">{item.token_symbol}</td>
                                    <td className="max-sm:px-2 max-sm:py-2 md:px-4 max-sm:hidden">{shortAddress(item.mintA)}</td>
                                    
                                    <td className="max-sm:px-2 max-sm:py-2 md:px-4">
                                      <div className='md:flex'>
                                        <div className='w-full'>{ solNumber(item.init_pc_amount)}</div>
                                        <div className='md:float-right max-sm:text-xs items-center text-slate-400  whitespace-nowrap'>{item.token_info?makePercent(item.init_coin_amount, item.supply) + "%": ""} </div>
                                      </div>
                                    </td>
                                    <td className="max-sm:px-2 max-sm:py-2 md:px-4 min-w-20">{varTime(item.open_time, item.blockTime)}</td>
                                    
                                    
                                    <td className="max-sm:px-2 max-sm:py-2 md:px-4">
                                      <div className='md:flex'>
                                        <div className='w-full'>{getLiquiditySOL(item.liquidityB)}</div>
                                        <div className='md:float-right max-sm:text-xs items-center whitespace-nowrap'>{ratioChange(getLiquidityBAmount(item.liquidityB), item.init_pc_amount)} </div>
                                      </div>
                                    </td>
                                    <td className="max-sm:px-2 max-sm:py-2 md:px-4">
                                          <div className='md:flex items-center gap-2'> 
                                              {showRateToken(item.burn_block, item.burn, item.remove, 0)} 
                                              {showRateMintAuthority(item.mintAuthority)}
                                              {showRateDexpaid(item.dexpaid, item.moonshot)} 
                                          </div>
                                    </td>
                                  
                              </tr> 
                            
                          )
                  })}
              </tbody>
            </table>
          </div>
          {/* Navigation - page */}
          <div className="w-full mx-auto  text-center fixed z-30 bottom-0 left-0 right-0 bg-head text-highlight p-1"> 
              <div className="">
                  <div className="flex justify-center space-x-2">
                      <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={prevPage}>[ &lt;&lt; ]</button>
                      <span>{page}</span>
                      <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={nextPage}>[ &gt;&gt; ]</button>
                  </div>
              </div>
          </div>
          {/* Login popup */}
          <Modal isOpen={isModalOpen} onClose={closeModal} />
      </div>
  )
}

export default TokensArea