import React, { useState, useEffect } from 'react';
import {shortAddress2, dollarCap, extensionLink, extensionLink2, externalMarket2, varTime , varTime2, solNumber} from '../../components/Helper';

const AnalysisGameArea = () => {
    const [page, setPage] = useState(1);
    const nextPage = () => {setPage(page + 1)};
    const prevPage = () => {if(page > 1) setPage(page - 1)};
    const [data, setData] = useState([]);


    useEffect(() => {
        loadData();
    }, [page]);

    async function loadData(){
        const limit = 500;
        const offset = limit * (page-1);
        var params = {"offset" : offset , "limit" : limit , "sort" : "id_game", "order" : "ASC"}; //blockTime

        fetch('https://tool.tokencheck.io/analysis/game',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
          )
          .then(response => {
            return response.json(); 
          })
          .then(data => {     
            setData(data);
          })
          .catch(error => {
            console.log('Fetch Error:', error);
          });
    }



    function getTimeVar(date){
        if(date == null || date === "") return "";
        const timestamp = new Date(date).getTime() / 1000;
        const current = new Date();
        const current_unix = current.getTime()/1000;

        const day = (current_unix - timestamp) / 24 / 3600;
        return day.toFixed(0) +  " day";
    }

    return (
        <div className="mx-auto md:mt-4  text-sm">
            <div className="overflow-x-auto	pb-10 x-board md:mt-4 p-4">
                <table className="table-auto w-full table-th-left" key="list">
                    <thead style={{"border-bottom": "1px #eee solid"}}> 
                        <tr><th>No</th><th>Logo</th><th>Id</th><th>Name</th><th>Create</th><th></th><th>Bot</th><th>Web</th><th>Twitter</th>
                            <th>T-Chat</th><th>T-Channel</th></tr>
                    </thead>

                    {data.map((item, index) => {

                            return (

                                <tbody>
                                    <tr key={index}>
                                        <td className='text-gray-500' >{index + (page - 1)  * 20 + 1}</td>
                                        <td className='px-2 py-1'><img src={item.image} className="token-image-ic" alt={item.name} /></td>
                                        <td className="text-gray-500">
                                                {extensionLink2("https://ton.app/games/momoai?id=" + item.id_ton, item.id_ton)}</td>

                                        <td>{item.name}</td>
                                        <td>{item.created_date}
                                            
                                        </td>
                                        <td className="text-highlight">{getTimeVar(item.created_date)}</td>

                                        <td>{extensionLink2(item.telegram_bot, "Bot")}</td>
                                        <td>{extensionLink2(item.website, "Web")}</td>
                                        <td>{extensionLink2(item.twitter, "Twitter")}</td>                             
                                        <td>{extensionLink2(item.telegram_chat, "T-Chat")}</td>      
                                        <td>{extensionLink2(item.telegram_channel, "T-Channel")}</td> 
                                    </tr>
                                </tbody>

                            )
                    })}

                </table>
            </div>


            <div className="w-full mx-auto  text-center fixed bottom-0 left-0 right-0 bg-head text-highlight p-1"> 
                <div className="justify-self-end">
                    <div className="flex justify-center space-x-2">
                        <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={prevPage}>[ &lt;&lt; ]</button>
                        <span>{page}</span>
                        <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={nextPage}>[ &gt;&gt; ]</button>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default AnalysisGameArea