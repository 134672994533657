import React, { useState, useEffect } from 'react';
import AnalysisPumpKingArea from './AnalysisPumpKingArea';
import Header from '../../components/header/Header';
import AnalysisHead from './AnalysisHead';

function AnalysisPumpKing() {
    return (
      <>
        <Header/>
        <main>
          <AnalysisHead/>
          <AnalysisPumpKingArea/>
        </main>
      </>
    )
  }
  
  export default AnalysisPumpKing