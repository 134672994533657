import Header from '../../components/header/Header';
import ToolNavigation from './ToolNavigation'; 
import ToolHead from '../tool-api/ToolHead';
import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import {extensionLink, shortTokenLink,  varTime} from '../../components/Helper';

/* eslint-disable react-hooks/exhaustive-deps */
function TxOwner() {

    const {mint } = useParams();

    const [mintAddress, setMintAddress] = useState('');
    const [data, setData] = useState(null);
    const [tx_owner, setTxOwner] = useState(null);
    const [tx_owner_track, setTxOwnerTrack] = useState(null);

    useEffect(() => {
        if(mint != null) setMintAddress(mint);
    },[mint]);

    useEffect(() => {
        if(mintAddress != null) {
            handleLoadDB();
        }
    },[mintAddress]);


    const handleMintChange = (e) => {
        const _mintAddress = e.target.value;
        console.log('Đã nhấn Enter! Giá trị:', _mintAddress);
        setMintAddress(_mintAddress);
    }

    const handleLoadDB = () => {
        if(mintAddress !== ""){
            fetch('https://tool.tokencheck.io/tx-owner/mint/' + mintAddress)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setData(data);
                    if(data.hasOwnProperty("statusCode") ) {
                        //không tồn tại data ( null hoặc dạng 500)
                    }
                    else{
                        
                        setTxOwnerTrack(JSON.parse(data.tx_owner_track));
                        setTxOwner(JSON.parse(data.tx_owner));
                    }
                })    
                .catch(error => {
                    console.log('Fetch Error:', error);
            });
        }
    }

    return (
      <><Header/>
        <div className="p-4 mx-auto">
            <ToolHead/>
            <div className="w-full mt-4 md:flex">
                <div className="w-1/6 max-sm:w-full max-sm:flex">
                    <ToolNavigation/>
                </div>
                <div className="w-5/6 max-sm:w-full max-sm:mt-8">
                <div className='w-full x-board p-4'>
                    <div className='items-center text-center'>
                        <div>Mint Token</div>
                        <div><input className="w-full p-1.5 text-black"    
                                                defaultValue={mintAddress}     
                                                // value = {current_tx}
                                                onChange={handleMintChange}  
                                                type="text"/>
                        </div>
                        {/* <button className='button border p-1 pl-2 pr-2' onClick={handleLoadDB}><i className="fa-solid fa-arrow-rotate-right"></i></button></div> */}
                    </div>

                    <div className="flex mt-10 gap-4">
                        <div className='w-1/2'>
                            <div className='text-orange-500'>Check DB : {data?varTime(data.updated_time):""}</div>
                            <div className='text-xs truncate'>{extensionLink(`https://tool.tokencheck.io/tx-owner/mint/${mintAddress}`)}</div>
                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                <pre>
                                    {
                                        data?JSON.stringify(data,undefined, 2):""
                                    }
                                </pre>
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <div>Mint LP</div>
                            <div><input className="w-full p-1.5 text-black"    
                                                    defaultValue={data?.mintLP}     
                                                    // value = {current_tx}
                                                    // onChange={handleMintChange}  
                                                    type="text"/>
                            </div>

                            <div className='text-xs truncate'>{extensionLink(`https://solscan.io/token/${data?.mintLP}`)}</div>

                            <div className='mt-4'>Owner</div>
                            <div><input className="w-full p-1.5 text-black"    
                                                    defaultValue={data?.owner}     
                                                    // value = {current_tx}
                                                    // onChange={handleMintChange}  
                                                    type="text"/>
                            </div>
                            <div className='text-xs truncate'>{extensionLink(`https://solscan.io/token/${data?.owner}`)}</div>

                            <div className='mt-4'><button className='border p-2'>Reload TX</button></div>
                            <div className='text-xs truncate'>{extensionLink(`https://api2.tokencheck.io/tx/owner/${data?.mintLP}/${data?.owner}`)}</div>
                        </div>
                    </div>
                    <div className="flex mt-10 gap-4">
                        <div className='w-1/2'>
                            <div className='text-orange-500'>Tx Owner</div>
                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                <pre>
                                    {
                                        tx_owner?JSON.stringify(tx_owner,undefined, 2):""
                                    }
                                </pre>
                            </div>
                        </div>
                        <div className='w-1/2 overflow-x-auto'>
                            <div className='text-orange-500'>List :</div>
                            {
                                tx_owner?.map((item, index) => {
                                    return (<div>{shortTokenLink(item.signature, "tx")} -- {varTime(item.blockTime)} -- {item.type}</div>);
                                })
                            }
                        </div>
                    </div>

                    <div className="flex mt-10 gap-4">
                        
                        <div className='w-1/2'>
                            <div className='w-full text-orange-500'>Tx Owner Track</div>
                            <div className='whitespace-normal bg-black overflow-x-auto mt-4'>
                                <pre>
                                    {
                                        tx_owner_track?JSON.stringify(tx_owner_track,undefined, 2):""
                                    }
                                </pre>
                            </div>

                        </div>
                        <div className='w-1/2'>
                            <div className='text-orange-500'>List :</div>
                            {
                                tx_owner_track?.map((item, index) => {
                                    return (<div>{shortTokenLink(item.signature, "tx")} -- {varTime(item.blockTime)}</div>);
                                })
                            }
                        </div>
                    </div>

                </div>
                </div>
            </div>
        </div>
      </>
    )
}
  

export default TxOwner;